import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  booleanAttribute,
  computed,
  inject,
  input,
  viewChild,
} from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { MESSAGES } from 'src/app/core/constants/strings.constants';
import { STRINGS } from './model/email-templates-v2.strings';
import { TEMPLATE_LANGUAGES } from './model/email-templates-v2.model';
import { select, Store } from '@ngxs/store';
import { distinctUntilChanged } from 'rxjs';
import {
  SetChangesValidity,
  SetDefaultTemplate,
  UpdateTemplateBody,
  UpdateTemplateLanguage,
  UpdateTemplateSubject,
} from './actions/email-templates-v2.actions';
import { PermissionQueries } from 'src/app/core/states/permissions.queries';
import { RolesFeatureKeys } from 'src/app/core/constants/roles.constants';
import { DropdownModule } from '../design-system/dropdown/dropdown.module';
import { CommonModule } from '@angular/common';
import { ToggleModule } from '../design-system/toggle/toggle.module';
import { MatIcon } from '@angular/material/icon';
import { TooltipModule } from '../design-system/tooltip/tooltip.module';
import { LanguageLabelPipe } from './pipes/language-label.pipe';
import { InputModule } from '../design-system/input/input.module';
import { RequiredLabelDirective } from '../../directives/required-label.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EmailTemplateLanguage } from '../../models/emails/v1/emails.model';
import { EmailTemplatesState } from '../email-management/state/email-templates.state';

@Component({
  selector: 'app-email-templates-v2',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    ToggleModule,
    InputModule,
    MatIcon,
    TooltipModule,
    LanguageLabelPipe,
    RequiredLabelDirective,
  ],
  templateUrl: './email-templates-v2.component.html',
  styleUrl: './email-templates-v2.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailTemplatesV2Component implements AfterViewInit {
  form = viewChild.required<NgForm>('form');

  allowSetDefaultTemplate = input(false, { transform: booleanAttribute });

  ignorePermissions = input(false, { transform: booleanAttribute });

  defaultTemplateHelper = input(true, { transform: booleanAttribute });

  ignoreNotFilledLanguages = input(false, { transform: booleanAttribute });

  required = input(false, { transform: booleanAttribute });

  template = select(EmailTemplatesState.currentTemplateModel);

  availableTemplatesLanguages = select(
    EmailTemplatesState.availableTemplatesLanguages
  );

  templateLanguages = computed(() => {
    if (
      this.ignoreNotFilledLanguages() &&
      !!this.availableTemplatesLanguages()?.length
    )
      return this.availableTemplatesLanguages();

    return TEMPLATE_LANGUAGES;
  });

  bodyCounter = computed(() => this.template()?.body.length ?? 0);

  disabled = input(false, { transform: booleanAttribute });

  viewOnly = select(
    PermissionQueries.hasPermissionViewOnly(RolesFeatureKeys.SETTINGS)
  );

  readonly PLACEHOLDER_STRING = MESSAGES.common.enterText;

  readonly METADATA_STRINGS = STRINGS.metadata;

  readonly TOOLTIPS_STRINGS = STRINGS.tooltips;

  readonly BODY_MAX_LENGTH: number = 500;

  private readonly store = inject(Store);

  private readonly destroyRef = inject(DestroyRef);

  ngAfterViewInit(): void {
    this.form()
      ?.statusChanges.pipe(
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((status: string) =>
        this.store.dispatch(new SetChangesValidity(status === 'VALID'))
      );
  }

  setDefaultTemplate(): void {
    this.store.dispatch(new SetDefaultTemplate());
  }

  updateTemplateLanguage(language: EmailTemplateLanguage): void {
    this.store.dispatch(new UpdateTemplateLanguage(language));
  }

  updateTemplateSubject(subject: string): void {
    this.store.dispatch(new UpdateTemplateSubject(subject));
  }

  updateTemplateBody(body: string): void {
    this.store.dispatch(new UpdateTemplateBody(body));
  }
}
