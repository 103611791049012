import { inject, Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { catchError, mergeMap, tap } from 'rxjs';
import { BuyOrderV2Service } from 'src/app/core/api/buy-order/v2/buy-order-v2.service';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { SendEmailProperties } from '../model/email-management.model';
import {
  SaveFailed,
  SaveSucceed,
  Saving,
} from 'src/app/core/actions/app.action';
import { EmailTemplatesState } from './email-templates.state';
import { SendEmail, SetOrderEmails } from '../actions/email-management.actions';
import { NotificationCenterService } from 'src/app/core/services/notification-center.service';
import { STRINGS } from '../model/email-management.strings';

export interface EmailManagementStateModel {
  orderEmails: string[];
}

@State<EmailManagementStateModel>({
  name: 'emailManagementState',
  defaults: {
    orderEmails: [],
  },
})
@Injectable()
export class EmailManagementState {
  private readonly buyOrderServiceV2 = inject(BuyOrderV2Service);

  private readonly notificationsService = inject(NotificationCenterService);

  private readonly store = inject(Store);

  @Action(SetOrderEmails, { cancelUncompleted: true })
  setOrderEmails(
    ctx: StateContext<EmailManagementStateModel>,
    payload: SetOrderEmails
  ) {
    ctx.patchState({
      orderEmails: [...payload.emails],
    });
  }

  @Action(SendEmail)
  sendEmail(ctx: StateContext<EmailManagementStateModel>, payload: SendEmail) {
    return ctx.dispatch(new Saving(false)).pipe(
      mergeMap(() =>
        this._sendEmails(ctx, payload.orderUuid, ctx.getState().orderEmails)
      ),
      mergeMap(() => ctx.dispatch(new SaveSucceed(false))),
      tap(() => {
        this.notificationsService.showToast(STRINGS.notifications.sending);
      })
    );
  }

  private _sendEmails(
    ctx: StateContext<EmailManagementStateModel>,
    orderUuid: string,
    emailAddresses: string[]
  ) {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const properties: SendEmailProperties =
      this._buildSendBuyOrderEmailProperties(emailAddresses);

    return this.buyOrderServiceV2
      .sendBuyOrderEmail(webshopUuid, orderUuid, properties)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _buildSendBuyOrderEmailProperties(
    emailAddresses: string[]
  ): SendEmailProperties {
    const emailTemplate = this.store.selectSnapshot(
      EmailTemplatesState.currentTemplateModel
    );

    return {
      emailSettings: {
        template: {
          language: emailTemplate.language,
          subject: emailTemplate.subject,
          body: emailTemplate.body,
        },
        supplierEmails: emailAddresses,
      },
    };
  }
}
