import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  booleanAttribute,
  computed,
  input,
  signal,
  viewChild,
} from '@angular/core';
import { debounceTime, fromEvent, Subject, takeUntil } from 'rxjs';
import { ColumnFilterParam } from '../../../../model/data-table-v2.model';

import { ColumnFilter, Filter, FilterTypes } from '../../model/filter.model';
import { OverlayDirective } from 'src/app/shared/components/design-system/overlay-container-v2/directives/overlay.directive';

@Component({
  selector: 'app-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterGroupComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  scrollContainer = viewChild<ElementRef>('scrollContainer');

  overlay = viewChild.required(OverlayDirective);

  filters = input<ColumnFilter[]>([]);

  disabled = input(false, { transform: booleanAttribute });

  showApplyAll = input(false, { transform: booleanAttribute });

  @Output()
  filtered: EventEmitter<ColumnFilterParam> =
    new EventEmitter<ColumnFilterParam>();

  shouldBeAbleToScroll = signal(false);

  private _scrollOffset = signal(0);

  canScrollPrevious = computed(() => this._scrollOffset() > 0);

  canScrollNext = computed(
    () =>
      this._scrollOffset() + this.scrollContainer().nativeElement.offsetWidth <
      this.optimizedContainerScrollWidth
  );

  readonly FILTER_TYPES = FilterTypes;

  private readonly offset: number = 500;

  private _destroy$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    fromEvent(window, 'resize')
      .pipe(debounceTime(333), takeUntil(this._destroy$))
      .subscribe(() => {
        this._setScrollNeeds();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters) {
      this._checkFiltersChanges(
        changes.filters.previousValue,
        changes.filters.currentValue
      );
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._setScrollNeeds();
    }, 0);
  }

  backward() {
    if (this.canScrollPrevious()) {
      this._scrollOffset.update(offset => (offset -= this.offset));

      this._scrollTo(this._scrollOffset());
    }
  }

  forward() {
    if (this.canScrollNext()) {
      this._scrollOffset.update(offset => (offset += this.offset));

      this._scrollTo(this._scrollOffset());
    }
  }

  trackByFilterKey(_index: number, filter: Filter) {
    return filter.key;
  }

  toFilter(params: ColumnFilterParam): void {
    this.filtered.emit(params);

    this.closeOverlay();
  }

  toRemoveFilter(columnKey: string): void {
    this.filtered.emit({
      columnKey,
      optionSelected: null,
      subOperator: null,
      values: null,
      applyAll: false,
    });
  }

  closeOverlay(): void {
    this.overlay()._closeOverlay();
  }

  /**
   * Checks scroll value.
   * This overrides the scrollOffset variable from the forward/backward events.
   * @param event Event
   */
  checkScroll(event: Event): void {
    this._scrollOffset.set((event.target as HTMLElement).scrollLeft);
  }

  /**
   * Subtracts 8px from both sides as padding to get a more consistent container's scroll width value.
   */
  get optimizedContainerScrollWidth(): number {
    return this.scrollContainer().nativeElement.scrollWidth - 16;
  }

  /**
   * Checks filters changes to only run scrollNeeds when really needed
   * @param previousFilters Filter[]
   * @param currentFilters Filter[]
   * @returns filters changes
   */
  private _checkFiltersChanges(
    previousFilters: Filter[],
    currentFilters: Filter[]
  ): void {
    if (JSON.stringify(previousFilters) === JSON.stringify(currentFilters))
      return;

    setTimeout(() => {
      this._setScrollNeeds();
    }, 333);
  }

  private _scrollTo(offset: number): void {
    this.scrollContainer().nativeElement.scrollTo({
      left: offset,
      behavior: 'smooth',
    });
  }

  private _setScrollNeeds(): void {
    this.shouldBeAbleToScroll.set(this._hasOverflow());
  }

  private _hasOverflow(): boolean {
    if (!this.scrollContainer()) return false;

    return (
      this.scrollContainer().nativeElement.offsetWidth <
      this.optimizedContainerScrollWidth
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
