import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { UrlReplacement } from 'src/app/shared/models/config/url-replacement.model';
import { ConfigStoreService } from '../../services/config-store.service';
import { JobsAdapter } from './adapters/jobs.adapter';
import { LinkedSourcesAdapter } from './adapters/linkedSources.adapter';
import { SupportedSourcesAdapter } from './adapters/supportedSources.adapter';
import { OptiplySupportedSourcesAdapter } from './adapters/optiplySupportedSources.adapter';
import {
  CreateLinkedSourcePayload,
  Job,
  LinkedSource,
  LinkedTarget,
  RetrieveExportJobsPerTapQueryParameters,
  RetrieveJobsPerTapQueryParameters,
  SupportedSource,
  SupportedSourceResponse,
  UpdateLinkedSourcePayload,
} from './model/integrations.model';
import { OptiplyLinkedSourcesAdapter } from './adapters/optiplyLinkedSources.adapter';
import { OptiplyJobsAdapter } from './adapters/optiplyJobs.adapter';
import { ExportJobsAdapter } from './adapters/exportJobs.adapter';
import { LinkedTargetsAdapter } from './adapters/linkedTargets.adapter';
import { LinkedSourcesTestAdapter } from './adapters/linkedSourcesTest.adapter';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {
  constructor(
    private http: HttpClient,
    private configStore: ConfigStoreService
  ) {}

  private _retrieveSupportedSources(
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'integrations',
      'v1',
      'retrieveSupportedSources',
      replacements
    );
    return this.http.get<SupportedSource[]>(endpoint);
  }

  private _retrieveOptiplySupportedSources(
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'optiplyIntegrations',
      'v1',
      'retrieveSupportedSources',
      replacements
    );
    return this.http.get<SupportedSource[]>(endpoint);
  }

  private _retrieveLinkedSources(
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'integrations',
      'v1',
      'retrieveLinkedSources',
      replacements
    );
    return this.http.get<LinkedSource[]>(endpoint, {
      params: { config: true },
    });
  }

  // private _retrieveLinkedSources(
  //   replacements: UrlReplacement[]
  // ): Observable<any> {
  //   const headers = new HttpHeaders({
  //     'x-api-key': '4JKWUk1pplawD9glfloRC9x2l8oF8sU18FPUNdjJ',
  //   });

  //   return this.http.get<LinkedSource[]>(
  //     'https://client-api.hotglue.xyz/production.hotglue.optiply.nl/p6BFCREDz/1127/linkedSources/?config=true',
  //     {
  //       params: { config: true },
  //       headers: headers,
  //     }
  //   );
  // }

  private _retrieveLinkedTargets(
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'integrations',
      'v1',
      'retrieveLinkedTargets',
      replacements
    );
    return this.http.get<LinkedSource[]>(endpoint, {
      params: { config: true },
    });
  }

  // private _retrieveLinkedTargets(
  //   replacements: UrlReplacement[]
  // ): Observable<any> {
  //   const headers = new HttpHeaders({
  //     'x-api-key': '4JKWUk1pplawD9glfloRC9x2l8oF8sU18FPUNdjJ',
  //   });

  //   return this.http.get<LinkedSource[]>(
  //     'https://client-api.hotglue.xyz/production.hotglue.optiply.nl/nfeL_OU1k/1127/linkedTargets',
  //     {
  //       params: { config: true },
  //       headers: headers,
  //     }
  //   );
  // }

  private _retrieveOptiplyLinkedSources(
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'optiplyIntegrations',
      'v1',
      'retrieveLinkedSources',
      replacements
    );
    return this.http.get<LinkedSource[]>(endpoint);
  }

  private _createLinkedSource(
    payload: CreateLinkedSourcePayload,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'integrations',
      'v1',
      'createLinkedSource',
      replacements
    );

    return this.http.post<any>(endpoint, payload);
  }

  private _createOptiplyLinkedSource(
    payload: CreateLinkedSourcePayload,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'optiplyIntegrations',
      'v1',
      'createLinkedSource',
      replacements
    );

    return this.http.post<any>(endpoint, payload);
  }

  private _updateLinkedSource(
    payload: UpdateLinkedSourcePayload,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'integrations',
      'v1',
      'updateLinkedSource',
      replacements
    );

    return this.http.patch<any>(endpoint, payload);
  }

  private _deleteLinkedSource(replacements: UrlReplacement[]): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'integrations',
      'v1',
      'deleteLinkedSource',
      replacements
    );

    return this.http.delete<any>(endpoint);
  }

  private _retrieveJobsPerTap(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'integrations',
      'v1',
      'retrieveJobsPerTap',
      replacements
    );
    return this.http.get<Job[]>(endpoint, { params: payload });
  }

  // private _retrieveJobsPerTap(
  //   payload: any,
  //   replacements: UrlReplacement[]
  // ): Observable<any> {
  //   const headers = new HttpHeaders({
  //     'x-api-key': '4JKWUk1pplawD9glfloRC9x2l8oF8sU18FPUNdjJ',
  //   });

  //   return this.http.get<LinkedSource[]>(
  //     'https://client-api.hotglue.xyz/production.hotglue.optiply.nl/jobs?count=50&tenant=1127&taps=montapacking',
  //     {
  //       params: { config: true },
  //       headers: headers,
  //     }
  //   );
  // }

  private _retrieveOptiplyJobs(
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'optiplyIntegrations',
      'v1',
      'retrieveJobs',
      replacements
    );
    return this.http.get<Job[]>(endpoint);
  }

  private _retrieveExportJobsPerTap(
    payload: any,
    replacements: UrlReplacement[]
  ): Observable<any> {
    const endpoint: string = this.configStore.getEndpointForResource(
      'integrations',
      'v1',
      'retrieveJobsPerTap',
      replacements
    );
    return this.http.get<Job[]>(endpoint, { params: payload });
  }

  // private _retrieveExportJobsPerTap(
  //   payload: any,
  //   replacements: UrlReplacement[]
  // ): Observable<any> {
  //   const headers = new HttpHeaders({
  //     'x-api-key': '4JKWUk1pplawD9glfloRC9x2l8oF8sU18FPUNdjJ',
  //   });

  //   return this.http.get<LinkedSource[]>(
  //     'https://client-api.hotglue.xyz/production.hotglue.optiply.nl/jobs?count=50&tenant=1127&targets=montapacking',
  //     {
  //       params: { config: true },
  //       headers: headers,
  //     }
  //   );
  // }

  retrieveSupportedSources(
    webshopUUID: string
  ): Observable<SupportedSourceResponse> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
      { name: 'flowID', value: 'p6BFCREDz' },
    ];

    return this._retrieveSupportedSources(replacements).pipe(
      switchMap(data => of(new SupportedSourcesAdapter().transform(data)))
    );
  }

  retrieveOptiplySupportedSources(
    webshopUUID: string
  ): Observable<SupportedSourceResponse> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
    ];

    return this._retrieveOptiplySupportedSources(replacements).pipe(
      switchMap(data =>
        of(new OptiplySupportedSourcesAdapter().transform(data))
      )
    );
  }

  retrieveLinkedSources(
    webshopUUID: string,
    webshopID: number
  ): Observable<LinkedSource[]> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
      { name: 'flowID', value: 'p6BFCREDz' },
      { name: 'webshopID', value: webshopID },
    ];

    return this._retrieveLinkedSources(replacements).pipe(
      switchMap(data => of(new LinkedSourcesAdapter().transform(data)))
    );
  }

  retrieveLinkedTargets(
    webshopUUID: string,
    webshopID: number
  ): Observable<LinkedTarget[]> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
      { name: 'flowID', value: 'nfeL_OU1k' },
      { name: 'webshopID', value: webshopID },
    ];

    return this._retrieveLinkedTargets(replacements).pipe(
      switchMap(data => of(new LinkedTargetsAdapter().transform(data)))
    );
  }

  retrieveOptiplyLinkedSources(
    webshopUUID: string
  ): Observable<LinkedSource[]> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
    ];

    return this._retrieveOptiplyLinkedSources(replacements).pipe(
      switchMap(data => of(new OptiplyLinkedSourcesAdapter().transform(data)))
    );
  }

  createLinkedSource(
    webshopUUID: string,
    webshopID: string,
    payload: CreateLinkedSourcePayload
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
      { name: 'flowID', value: 'p6BFCREDz' },
      { name: 'webshopID', value: webshopID },
    ];

    return this._createLinkedSource(payload, replacements);
  }

  createOptiplyLinkedSource(
    webshopUUID: string,
    coupling: string,
    payload: CreateLinkedSourcePayload
  ): Observable<Job[]> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
      { name: 'coupling', value: coupling },
    ];

    return this._createOptiplyLinkedSource(payload, replacements);
  }

  updateLinkedSource(
    webshopUUID: string,
    webshopID: number,
    payload: UpdateLinkedSourcePayload
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
      { name: 'flowID', value: 'p6BFCREDz' },
      { name: 'webshopID', value: webshopID },
    ];

    return this._updateLinkedSource(payload, replacements);
  }

  deleteLinkedSource(
    webshopUUID: string,
    webshopID: number,
    tapName: string
  ): Observable<any> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
      { name: 'flowID', value: 'p6BFCREDz' },
      { name: 'webshopID', value: webshopID },
      { name: 'tapName', value: tapName },
    ];

    return this._deleteLinkedSource(replacements);
  }

  retrieveJobsPerTap(
    webshopUUID: string,
    payload: RetrieveJobsPerTapQueryParameters
  ): Observable<Job[]> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
    ];

    return this._retrieveJobsPerTap(payload, replacements).pipe(
      switchMap(data => of(new JobsAdapter().transform(data)))
    );
  }

  retrieveOptiplyJobs(webshopUUID: string): Observable<Job[]> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
    ];

    return this._retrieveOptiplyJobs(replacements).pipe(
      switchMap(data => of(new OptiplyJobsAdapter().transform(data)))
    );
  }

  retrieveExportJobsPerTap(
    webshopUUID: string,
    payload: RetrieveExportJobsPerTapQueryParameters
  ): Observable<Job[]> {
    const replacements: UrlReplacement[] = [
      { name: 'webshopUUID', value: webshopUUID },
    ];

    return this._retrieveExportJobsPerTap(payload, replacements).pipe(
      switchMap(data => of(new ExportJobsAdapter().transform(data)))
    );
  }
}
