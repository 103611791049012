import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { InputError, InputVariant } from './model/input.model';

@Component({
  selector: 'app-input, input[appInput], textarea[appInput]',
  template: `<ng-content></ng-content>`,
  styleUrl: './input.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class InputComponent implements OnChanges {
  @HostBinding('class')
  class: string;

  @Input({ required: true })
  variant: InputVariant = 'filled';

  @Input()
  error: InputError = 'onchange';

  ngOnChanges(_changes: SimpleChanges): void {
    this._applyClass();
  }

  private _applyClass(): void {
    this.class = `input-base input-${this.variant} input-error-${this.error}`;
  }
}
