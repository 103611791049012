import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { DateTime } from 'luxon';
import { catchError, concatMap, mergeMap, Observable, of, tap } from 'rxjs';
import {
  LoadFailed,
  LoadSucceed,
  Loading,
  SaveFailed,
  SaveSucceed,
  Saving,
} from 'src/app/core/actions/app.action';
import {
  SetInitialData,
  SetPendingChanges,
  UnsetAllPendingChanges,
} from 'src/app/core/actions/pending-changes.action';
import {
  UpdateMultipleWebshopProducts,
  UpdateMultipleWebshopProductsProperties,
  UpdateWebshopProductProperties,
} from 'src/app/core/api/products/v3/model/products-v3.model';
import { ProductsV3Service } from 'src/app/core/api/products/v3/products-v3.service';
import { WebshopState } from 'src/app/core/states/webshop.state';
import {
  LoadProductSalesV2,
  ProductSalesV2Reset,
  ProductSalesV2SetInitialState,
} from 'src/app/shared/components/product-details-v2/components/sales-v2/actions/sales-v2.actions';
import {
  LoadSupplyChainInformation,
  ResetSupplyChainInformation,
} from 'src/app/shared/components/product-details-v2/components/supply-chain-information-v2/actions/supply-chain-information-v2.actions';
import { PendingChangesKeys } from 'src/app/shared/models/navigation/pending-changes.model';
import {
  PredecessorProduct,
  PredecessorProducts,
  SuccessorProduct,
  WebshopProduct,
} from 'src/app/shared/models/products/v3/products.model';
import { WebshopProductSettings } from '../../../model/products-v2.model';
import {
  CancelNotes,
  CancelProductChanges,
  GoToProduct,
  InitializeProductDetailsV2State,
  InitializeProductDetailsV2StateForOverlay,
  LinkPredecessor,
  LoadProduct,
  LoadProductAvailablePredecessors,
  ManageProductCompositionsTab,
  ManageProductPartsTab,
  ResetProductDetails,
  SaveNotes,
  SaveProductChanges,
  SetProductIgnoredTemplate,
  SetProductUuid,
  UpdateManualServiceLevel,
  UpdateNotes,
  UpdateProductIgnored,
  UpdateProductMaximumStock,
  UpdateProductMinimumStockLevel,
  UpdateProductPhaseOut,
  UpdateProductPhaseOutEndDate,
  UpdateProductResumePurchaseDate,
} from '../actions/product-details-v2.actions';
import {
  defaultOverview,
  defaultWebshopProductSettings,
  Overview,
} from '../model/product-details-v2.model';
import { NavigateToWithQueryParams } from 'src/app/core/actions/navigation.action';
import { NULL_VALUE } from 'src/app/shared/models/core.model';
import {
  ProductAvailableRequestInfo,
  ProductLink,
  ProductLinkProperties,
} from 'src/app/shared/components/product-details-v2/components/product-link-dialog/model/product-link.model';
import { CheckProductCompositionsV2DataExistence } from 'src/app/shared/components/product-details-v2/components/product-compositions-v2/actions/product-compositions-v2.actions';
import { CheckProductPartsV2DataExistence } from 'src/app/shared/components/product-details-v2/components/product-parts-v2/actions/product-parts-v2.actions';
import { ServiceRequestInfoV3QueryingTypes } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { InitializeWebshopProductSuppliersState } from 'src/app/shared/components/product-details-v2/components/product-suppliers-v2/actions/product-suppliers-v2.actions';
import { InitializeWebshopProductPromotionsState } from 'src/app/shared/components/product-details-v2/components/product-promotions-v2/actions/product-promotions-v2.actions';
import { PermissionQueries } from 'src/app/core/states/permissions.queries';
import { RolesFeatureKeys } from 'src/app/core/constants/roles.constants';

export interface ProductDetailsV2StateModel {
  uuid: string;
  saving: boolean;
  isOverlay: boolean;
  overview: Overview;
  settings: WebshopProductSettings;
  settingsOriginal: WebshopProductSettings;
  disableCompositionsTab: boolean;
  disablePartsTab: boolean;
  ignoredTemplate: boolean;
  predecessor: PredecessorProduct | null;
  successor: SuccessorProduct | null;
  availablePredecessors: PredecessorProduct[];
  saveEnabled: boolean;
  formValid: boolean;
}

@State<ProductDetailsV2StateModel>({
  name: 'productDetailsV2State',
  defaults: {
    uuid: null,
    saving: false,
    isOverlay: false,
    overview: defaultOverview,
    settings: defaultWebshopProductSettings,
    settingsOriginal: defaultWebshopProductSettings,
    disableCompositionsTab: true,
    disablePartsTab: true,
    ignoredTemplate: false,
    predecessor: null,
    successor: null,
    availablePredecessors: [],
    saveEnabled: true,
    formValid: true,
  },
})
@Injectable()
export class ProductDetailsV2State {
  constructor(
    private store: Store,
    private productsV3Service: ProductsV3Service
  ) {}

  @Action(SetProductUuid, { cancelUncompleted: true })
  setProductUuid(
    ctx: StateContext<ProductDetailsV2StateModel>,
    payload: SetProductUuid
  ) {
    ctx.patchState({
      uuid: payload.productUuid,
    });
  }

  @Action(InitializeProductDetailsV2State, { cancelUncompleted: true })
  initialize(
    ctx: StateContext<ProductDetailsV2StateModel>,
    payload: InitializeProductDetailsV2State
  ) {
    ctx.patchState({
      uuid: payload.productUuid,
    });

    return ctx.dispatch(new Loading(false)).pipe(
      tap(() => {
        if (payload.forceInitialization) {
          ctx.patchState({
            saving: false,
            isOverlay: false,
            overview: defaultOverview,
            settings: defaultWebshopProductSettings,
            settingsOriginal: defaultWebshopProductSettings,
            disableCompositionsTab: true,
            disablePartsTab: true,
            ignoredTemplate: false,
            predecessor: null,
            successor: null,
          });
        }
      }),
      mergeMap(() => {
        if (
          !!ctx.getState().overview.product.id &&
          !payload.forceInitialization
        ) {
          return of(null);
        }

        return ctx.dispatch(new LoadProduct());
      }),
      concatMap(() => {
        if (ctx.getState().overview.product.ignored) {
          return ctx.dispatch(new ResetSupplyChainInformation());
        }

        return ctx.dispatch(new LoadSupplyChainInformation());
      }),
      mergeMap(() => {
        if (ctx.getState().overview.product.ignored) return of(null);

        return ctx.dispatch([
          new CheckProductCompositionsV2DataExistence(),
          new CheckProductPartsV2DataExistence(),
        ]);
      }),
      mergeMap(() => {
        if (ctx.getState().overview.product.ignored) {
          return ctx.dispatch(new ProductSalesV2Reset());
        }
      }),
      mergeMap(() => ctx.dispatch(new LoadSucceed(false))),
      catchError(() => {
        return ctx.dispatch(new LoadFailed(false));
      })
    );
  }

  @Action(InitializeProductDetailsV2StateForOverlay, {
    cancelUncompleted: true,
  })
  initializeForOverlay(
    ctx: StateContext<ProductDetailsV2StateModel>,
    payload: InitializeProductDetailsV2StateForOverlay
  ) {
    ctx.patchState({
      uuid: payload.productUuid,
      isOverlay: true,
    });

    let promotionsPermission = this.store.selectSnapshot(
      PermissionQueries.hasPermission(RolesFeatureKeys.PROMOTIONS)
    );

    return ctx.dispatch(new LoadProduct()).pipe(
      concatMap(() => {
        if (ctx.getState().overview.product.ignored) {
          return ctx.dispatch(new ResetSupplyChainInformation());
        }

        if (promotionsPermission) {
          return ctx.dispatch([
            new LoadSupplyChainInformation(),
            new InitializeWebshopProductSuppliersState(),
            new InitializeWebshopProductPromotionsState(),
            new ProductSalesV2SetInitialState(),
          ]);
        }
        return ctx.dispatch([
          new LoadSupplyChainInformation(),
          new InitializeWebshopProductSuppliersState(),
          new ProductSalesV2SetInitialState(),
        ]);
      }),

      mergeMap(() => {
        if (ctx.getState().overview.product.ignored) {
          return ctx.dispatch(new ProductSalesV2Reset());
        }
      })
    );
  }

  @Action(LoadProduct, { cancelUncompleted: true })
  loadProduct(ctx: StateContext<ProductDetailsV2StateModel>) {
    ctx.patchState({
      overview: {
        ...ctx.getState().overview,
        loading: true,
      },
    });

    return this._findProduct(ctx, ctx.getState().uuid).pipe(
      tap((product: WebshopProduct) => {
        const productSettings: WebshopProductSettings = {
          notBeingBought: product.notBeingBought,
          notBeingBoughtHasEndDate: !!product.resumingPurchase,
          minPurchaseDate: new Date().toUTCString(),
          resumePurchaseDate: product.resumingPurchase,
          minimumStockLevel: product.minimumStock,
          maximumStock: product.maximumStock,
          ignored: product.ignored,
          manualServiceLevel: product.manualServiceLevel,
          notes: product.notes,
        };

        ctx.patchState({
          overview: {
            product,
            loading: false,
            failed: false,
          },
          settings: productSettings,
          settingsOriginal: productSettings,
          ignoredTemplate: product.ignored,
          predecessor: product.predecessor,
          successor: product.successor,
        });

        ctx.dispatch(
          new SetInitialData(
            PendingChangesKeys.OVERLAY_PRODUCTS,
            productSettings
          )
        );
      })
    );
  }

  @Action(ResetProductDetails, { cancelUncompleted: true })
  reset(ctx: StateContext<ProductDetailsV2StateModel>) {
    ctx.patchState({
      uuid: null,
      saving: false,
      isOverlay: false,
      overview: defaultOverview,
      settings: defaultWebshopProductSettings,
      settingsOriginal: defaultWebshopProductSettings,
      disableCompositionsTab: true,
      disablePartsTab: true,
      ignoredTemplate: false,
      predecessor: null,
      successor: null,
    });

    return ctx.dispatch([
      new ResetSupplyChainInformation(),
      new ProductSalesV2Reset(),
    ]);
  }

  @Action(LoadProductAvailablePredecessors, { cancelUncompleted: true })
  loadProductAvailablePredecessors(
    ctx: StateContext<ProductDetailsV2StateModel>,
    payload: LoadProductAvailablePredecessors
  ) {
    if (payload.search.length === 0) {
      ctx.patchState({ availablePredecessors: [] });
      return;
    }

    const requestInfo = this._buildFindProductAvailablePredecessors(
      payload.search
    );

    return ctx.dispatch(new Loading(false)).pipe(
      concatMap(() => this._findProductAvailablePredecessors(ctx, requestInfo)),
      tap((availablePredecessors: PredecessorProducts) => {
        ctx.patchState({ availablePredecessors: availablePredecessors.data });
      }),
      concatMap(() => ctx.dispatch(new LoadSucceed(false)))
    );
  }

  @Action(LinkPredecessor, { cancelUncompleted: true })
  linkPredecessor(
    ctx: StateContext<ProductDetailsV2StateModel>,
    payload: LinkPredecessor
  ) {
    const requestInfo = this._buildPredecessorLinkRequestInfo(
      ctx.getState().uuid,
      payload.link
    );

    return ctx.dispatch(new Loading()).pipe(
      mergeMap(() => this._linkPredecessor(ctx, requestInfo)),
      tap(() => {
        payload.dialogRef.close();
      }),
      concatMap(() => ctx.dispatch([new LoadSucceed(), new LoadProduct()]))
    );
  }

  @Action(UpdateProductIgnored, { cancelUncompleted: true })
  updateIgnored(
    ctx: StateContext<ProductDetailsV2StateModel>,
    payload: UpdateProductIgnored
  ) {
    ctx.setState(
      patch<ProductDetailsV2StateModel>({
        settings: patch<WebshopProductSettings>({
          ignored: payload.change.value,
        }),
      })
    );

    return this._setPendingChanges(ctx, payload.change.valid);
  }

  @Action(UpdateProductMinimumStockLevel, { cancelUncompleted: true })
  updateMinimumStockLevel(
    ctx: StateContext<ProductDetailsV2StateModel>,
    payload: UpdateProductMinimumStockLevel
  ) {
    ctx.setState(
      patch<ProductDetailsV2StateModel>({
        settings: patch<WebshopProductSettings>({
          minimumStockLevel: payload.change.value,
        }),
      })
    );

    return this._setPendingChanges(ctx, payload.change.valid);
  }

  @Action(UpdateProductMaximumStock, { cancelUncompleted: true })
  updateProductMaximumStock(
    ctx: StateContext<ProductDetailsV2StateModel>,
    payload: UpdateProductMaximumStock
  ) {
    ctx.setState(
      patch<ProductDetailsV2StateModel>({
        settings: patch<WebshopProductSettings>({
          maximumStock: payload.change.value,
        }),
      })
    );

    return this._setPendingChanges(ctx, payload.change.valid);
  }

  @Action(UpdateManualServiceLevel, { cancelUncompleted: true })
  updateManualServiceLevel(
    ctx: StateContext<ProductDetailsV2StateModel>,
    payload: UpdateManualServiceLevel
  ) {
    ctx.setState(
      patch<ProductDetailsV2StateModel>({
        settings: patch<WebshopProductSettings>({
          manualServiceLevel: payload.change.value,
        }),
      })
    );

    return this._setPendingChanges(ctx, payload.change.valid);
  }

  @Action(UpdateProductPhaseOut, { cancelUncompleted: true })
  updatePhaseOut(
    ctx: StateContext<ProductDetailsV2StateModel>,
    payload: UpdateProductPhaseOut
  ) {
    ctx.setState(
      patch<ProductDetailsV2StateModel>({
        settings: patch<WebshopProductSettings>({
          notBeingBought: payload.change.value,
          notBeingBoughtHasEndDate: false,
          resumePurchaseDate: null,
        }),
      })
    );

    return this._setPendingChanges(ctx, payload.change.valid);
  }

  @Action(UpdateProductPhaseOutEndDate, { cancelUncompleted: true })
  updatePhaseOutEndDate(
    ctx: StateContext<ProductDetailsV2StateModel>,
    payload: UpdateProductPhaseOutEndDate
  ) {
    ctx.setState(
      patch<ProductDetailsV2StateModel>({
        settings: patch<WebshopProductSettings>({
          notBeingBoughtHasEndDate: payload.change.value,
          resumePurchaseDate: null,
        }),
      })
    );

    return this._setPendingChanges(ctx, payload.change.valid);
  }

  @Action(UpdateProductResumePurchaseDate, { cancelUncompleted: true })
  updateResumePurchaseDate(
    ctx: StateContext<ProductDetailsV2StateModel>,
    payload: UpdateProductResumePurchaseDate
  ) {
    ctx.setState(
      patch<ProductDetailsV2StateModel>({
        settings: patch<WebshopProductSettings>({
          resumePurchaseDate: payload.change.value,
        }),
      })
    );

    return this._setPendingChanges(ctx, payload.change.valid);
  }

  @Action(CancelProductChanges, { cancelUncompleted: true })
  cancel(ctx: StateContext<ProductDetailsV2StateModel>) {
    const state = ctx.getState();

    ctx.patchState({
      settings: state.settingsOriginal,
      formValid: true,
    });

    return this._setPendingChanges(ctx, true);
  }

  @Action(SaveProductChanges, { cancelUncompleted: true })
  save(ctx: StateContext<ProductDetailsV2StateModel>) {
    ctx.patchState({
      saving: true,
    });

    return ctx.dispatch(new Saving()).pipe(
      mergeMap(() => this._updateProduct(ctx)),
      tap(() => {
        ctx.patchState({
          saving: false,
        });
      }),
      concatMap(() => ctx.dispatch(new UnsetAllPendingChanges())),
      mergeMap(() => ctx.dispatch([new SaveSucceed(), new LoadProduct()])),
      concatMap(() => {
        if (ctx.getState().overview.product.ignored) {
          return ctx.dispatch(new ResetSupplyChainInformation());
        }

        return ctx.dispatch(new LoadSupplyChainInformation());
      }),
      mergeMap(() => {
        if (ctx.getState().overview.product.ignored) {
          return ctx.dispatch(new ProductSalesV2Reset());
        }

        return ctx.dispatch(new LoadProductSalesV2());
      })
    );
  }

  @Action(UpdateNotes, { cancelUncompleted: true })
  updateNotes(
    ctx: StateContext<ProductDetailsV2StateModel>,
    payload: UpdateNotes
  ) {
    ctx.setState(
      patch<ProductDetailsV2StateModel>({
        saveEnabled: false,
        settings: patch<WebshopProductSettings>({
          notes: payload.change,
        }),
      })
    );
  }

  @Action(CancelNotes, { cancelUncompleted: true })
  cancelNotes(ctx: StateContext<ProductDetailsV2StateModel>) {
    const state = ctx.getState();

    ctx.patchState({
      saveEnabled: true,
      settings: {
        ...state.settings,
        notes: state.overview.product.notes,
      },
    });
  }

  @Action(SaveNotes, { cancelUncompleted: true })
  saveNotes(ctx: StateContext<ProductDetailsV2StateModel>) {
    return ctx.dispatch(new Saving()).pipe(
      concatMap(() => this._updateNotes(ctx)),
      tap(() => {
        ctx.patchState({
          saveEnabled: true,
        });
      }),
      concatMap(() => ctx.dispatch(new SaveSucceed())),
      concatMap(() => ctx.dispatch(new LoadProduct())),
      catchError(() => {
        return ctx.dispatch(new SaveFailed());
      })
    );
  }

  @Action(ManageProductCompositionsTab, { cancelUncompleted: true })
  manageProductCompositionsTab(
    ctx: StateContext<ProductDetailsV2StateModel>,
    { payload }: ManageProductCompositionsTab
  ) {
    ctx.patchState({
      disableCompositionsTab: payload.disable,
    });
  }

  @Action(ManageProductPartsTab, { cancelUncompleted: true })
  manageProductPartsTab(
    ctx: StateContext<ProductDetailsV2StateModel>,
    { payload }: ManageProductPartsTab
  ) {
    ctx.patchState({
      disablePartsTab: payload.disable,
    });
  }

  @Action(GoToProduct, { cancelUncompleted: true })
  goToProduct(
    ctx: StateContext<ProductDetailsV2StateModel>,
    payload: GoToProduct
  ) {
    ctx.patchState({
      uuid: payload.productUuid,
    });

    return ctx.dispatch(new LoadProduct()).pipe(
      concatMap(() =>
        this.store.dispatch(
          new NavigateToWithQueryParams(['products', 'details'], {
            productUuid: payload.productUuid,
          })
        )
      )
    );
  }

  @Action(SetProductIgnoredTemplate, { cancelUncompleted: true })
  setProductIgnoredTemplate(ctx: StateContext<ProductDetailsV2StateModel>) {
    ctx.patchState({
      ignoredTemplate: true,
    });
  }

  private _findProduct(
    ctx: StateContext<ProductDetailsV2StateModel>,
    productUuid: string
  ): Observable<WebshopProduct | void> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.productsV3Service.findOne(webshopUuid, productUuid).pipe(
      catchError(e => {
        ctx.patchState({
          overview: {
            ...defaultOverview,
            failed: true,
          },
        });

        ctx.dispatch(new LoadFailed());

        throw new Error(e.message || e);
      })
    );
  }

  private _updateProduct(
    ctx: StateContext<ProductDetailsV2StateModel>
  ): Observable<any> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const productUuid = ctx.getState().overview.product.uuid;

    const properties = this._buildUpdateProductRequestInfo(ctx.getState());

    return this.productsV3Service
      .update(webshopUuid, productUuid, properties)
      .pipe(
        catchError(e => {
          ctx.patchState({
            saving: false,
          });

          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _buildUpdateProductRequestInfo(
    state: ProductDetailsV2StateModel
  ): Partial<UpdateWebshopProductProperties> {
    const enableProductMaximumStockLevel = this.store.selectSnapshot(
      WebshopState.productMaximumStockLevelEnabled
    );

    let properties: Partial<UpdateWebshopProductProperties>;

    if (enableProductMaximumStockLevel) {
      properties = {
        notBeingBought: state.settings.notBeingBought,
        resumingPurchase: {
          null: 'NULL_VALUE',
        },
        minimumStock: {
          null: 'NULL_VALUE',
        },
        maximumStock: {
          null: 'NULL_VALUE',
        },
        ignored: state.settings.ignored,
        manualServiceLevel: {
          null: 'NULL_VALUE',
        },
      };

      if (state.settings.maximumStock) {
        properties.maximumStock = {
          value: state.settings.maximumStock,
        };
      }
    } else {
      properties = {
        notBeingBought: state.settings.notBeingBought,
        resumingPurchase: {
          null: 'NULL_VALUE',
        },
        minimumStock: {
          null: 'NULL_VALUE',
        },
        manualServiceLevel: {
          null: 'NULL_VALUE',
        },
        ignored: state.settings.ignored,
      };
    }

    if (state.settings.notBeingBoughtHasEndDate) {
      const resumingPurchaseDate = DateTime.fromJSDate(
        new Date(state.settings.resumePurchaseDate)
      ).toFormat('yyyy-MM-dd');

      properties.resumingPurchase = {
        value: resumingPurchaseDate,
      };
    }

    if (state.settings.minimumStockLevel) {
      properties.minimumStock = {
        value: state.settings.minimumStockLevel,
      };
    }

    if (state.settings.manualServiceLevel) {
      properties.manualServiceLevel = {
        value: state.settings.manualServiceLevel,
      };
    }

    return properties;
  }

  private _setPendingChanges(
    ctx: StateContext<ProductDetailsV2StateModel>,
    validChanges: boolean
  ): Observable<any> {
    const state = ctx.getState();

    ctx.patchState({
      formValid: validChanges,
    });

    return ctx.dispatch(
      new SetPendingChanges(
        PendingChangesKeys.OVERLAY_PRODUCTS,
        validChanges,
        state.settings
      )
    );
  }

  private _findProductAvailablePredecessors(
    ctx: StateContext<ProductDetailsV2StateModel>,
    requestInfo: any
  ): Observable<PredecessorProducts | void> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const webshopProductUuid = ctx.getState().uuid;

    return this.productsV3Service
      .findProductAvailablePredecessors(
        webshopUuid,
        webshopProductUuid,
        requestInfo
      )
      .pipe(
        catchError(() => {
          ctx.patchState({
            predecessor: null,
          });

          return ctx.dispatch(new LoadFailed());
        })
      );
  }

  private _buildFindProductAvailablePredecessors(
    search: string
  ): ProductAvailableRequestInfo {
    const requestInfo: ProductAvailableRequestInfo = {
      queryData: {
        query: {
          '@type': ServiceRequestInfoV3QueryingTypes.STRING,
          value: search,
        },
        page: {
          from: 0,
          size: 200,
        },
      },
    };

    return requestInfo;
  }

  private _linkPredecessor(
    ctx: StateContext<ProductDetailsV2StateModel>,
    requestInfo: UpdateMultipleWebshopProductsProperties
  ): Observable<any> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.productsV3Service.multipleUpdate(webshopUuid, requestInfo).pipe(
      catchError(() => {
        return ctx.dispatch(new SaveFailed());
      })
    );
  }

  private _updateNotes(ctx: StateContext<ProductDetailsV2StateModel>) {
    const state = ctx.getState();

    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const productUuid = ctx.getState().overview.product.uuid;

    let notes;

    if (state.settings.notes !== '') {
      notes = {
        value: state.settings.notes,
      };
    } else {
      notes = {
        null: 'NULL_VALUE',
      };
    }

    return this.productsV3Service
      .update(webshopUuid, productUuid, {
        notes: notes,
      })
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _buildPredecessorLinkRequestInfo(
    webshopProductUuid: string,
    link: ProductLink
  ): UpdateMultipleWebshopProductsProperties {
    const products: UpdateMultipleWebshopProducts[] = [];

    let linking: ProductLinkProperties = {
      null: NULL_VALUE,
    };

    if (link !== null) {
      linking = {
        value: link.predecessor.uuid,
      };

      products.push({
        uuid: link.predecessor.uuid,
        notBeingBought: link.notBeingBought,
      });
    }

    products.push({
      uuid: webshopProductUuid,
      predecessorUuid: linking,
    });

    return {
      webshopProducts: products,
    };
  }
}
