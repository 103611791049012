import { DataAdapter } from 'src/app/shared/adapter/adapter';
import { LinkedSource } from '../model/integrations.model';

export class LinkedSourcesAdapter implements DataAdapter<any, LinkedSource[]> {
  transform(linkedSources: any): LinkedSource[] {
    const newlinkedSources = linkedSources.map(linkedSource => {
      if (linkedSource.connect_ui_params) {
        for (let i = 0; i < linkedSource.connect_ui_params.length; i++) {
          let properties = Object.values(linkedSource.connect_ui_params[i])[0];
          linkedSource.connect_ui_params[i] = Object.assign({}, properties, {
            key: Object.keys(linkedSource.connect_ui_params[i])[0],
          });
        }
      }
      var source: LinkedSource = {
        tap: linkedSource.tap,
        label: linkedSource.label,
        icon: linkedSource.icon,
        connectedUiParams: linkedSource.connect_ui_params
          ? linkedSource.connect_ui_params
          : null,
        jobs: null,
        exportJobs: null,
        loadingJobs: true,
        tenant: linkedSource.tenant_id,
      };
      if (source.connectedUiParams) {
        for (let field of source.connectedUiParams) {
          field.value = linkedSource.config[field.key];
        }
      }
      return source;
    });

    newlinkedSources.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

    return newlinkedSources;
  }
}
