<ng-container *ngIf="!!value">
  <form #form="ngForm" class="filter-equals-option-form">
    <label for="to">{{ COMMON_STRINGS.filter.optionTypes.equals }}</label>

    <input
      appInput
      variant="filled"
      id="to"
      [(ngModel)]="value.to"
      name="to"
      type="text"
      [placeholder]="COMMON_STRINGS.enterText"
      autocomplete="off"
      (input)="onChanged(value)" />
  </form>
</ng-container>
