@if (linkedSource?.loadingJobs) {
  <div class="loading-jobs-container">
    <app-progress-spinner></app-progress-spinner>
    <p
      class="loading-warning"
      i18n="settings integrations jobs loading warning">
      Loading jobs...
    </p>
  </div>
} @else {
  <div class="jobs-container">
    @if (!jobs || jobs.length === 0) {
      <div
        class="no-data-container"
        i18n="settings integrations jobs no jobs available">
        There are no jobs to show.
      </div>
    } @else {
      <app-accordion>
        @for (job of jobs; track job) {
          @if (job.hotglue) {
            <app-expansion-panel-jobs [expanded]="panelOpen.get(job.name)">
              <app-expansion-panel-jobs-header>
                <app-expansion-panel-jobs-title>
                  <div class="panel-title-container">
                    <div class="source-row-left">
                      <p class="panel-title">
                        {{ job.name }}
                      </p>
                    </div>
                    <div class="source-row-right">
                      <div class="job-date">
                        {{
                          (job.status | jobStatus) === 'running'
                            ? (job.startDate | date: 'short')
                            : (job.lastUpdateDate | date: 'short')
                        }}
                      </div>
                      @switch (job.status | jobStatus) {
                        @case ('complete') {
                          @if (job.errors && job.errors.length > 0) {
                            <div
                              class="job-status job-status-completed-with-fails">
                              <mat-icon
                                class="status-icon"
                                svgIcon="warning-triangle"></mat-icon>
                              <p>{{ STRINGS_JOBS.completeWithFails }}</p>
                            </div>
                          } @else {
                            <div class="job-status job-status-completed">
                              <mat-icon
                                class="status-icon"
                                svgIcon="check-xs"></mat-icon>
                              <p>{{ STRINGS_JOBS.complete }}</p>
                            </div>
                          }
                        }
                        @case ('queued') {
                          <div class="job-status job-status-queued">
                            <mat-icon
                              class="status-icon"
                              svgIcon="pause"></mat-icon>
                            <p>{{ STRINGS_JOBS.queued }}</p>
                          </div>
                        }
                        @case ('failed') {
                          <div class="job-status job-status-failed">
                            <mat-icon
                              class="status-icon"
                              svgIcon="cross-xs"></mat-icon>
                            <p>{{ STRINGS_JOBS.failed }}</p>
                          </div>
                        }
                        @default {
                          <div class="job-status job-status-running">
                            <app-progress-spinner
                              diameter="14"></app-progress-spinner>
                            <p>{{ STRINGS_JOBS.running }}</p>
                          </div>
                        }
                      }
                    </div>
                  </div>
                </app-expansion-panel-jobs-title>
              </app-expansion-panel-jobs-header>

              <div
                *ngIf="job.metrics && (job.status | jobStatus) === 'running'"
                class="job-failed-container">
                <mat-icon
                  class="info-icon"
                  svgIcon="info-rounded-xs"></mat-icon>
                <p class="sub-title">
                  {{ STRINGS_JOBS.waitWarning }}
                </p>
              </div>

              <div
                *ngIf="job.metrics && (job.status | jobStatus) === 'failed'"
                class="job-failed-container">
                <mat-icon
                  class="info-icon"
                  svgIcon="info-rounded-xs"></mat-icon>
                <p class="sub-title">
                  {{ STRINGS_JOBS.errorWarning }}
                </p>
              </div>

              <div class="inside-panel-container">
                @if (job.metrics) {
                  <div class="container">
                    @for (
                      metric of job.metrics | keyvalue;
                      track metric;
                      let idx = $index
                    ) {
                      <div
                        [class.container-col-bordered]="idx % 4 < 3"
                        [class.container-col-bordered-bottom]="
                          borderedBottom(job.totalRows, idx)
                        "
                        class="container-col">
                        <span class="container-primary-label">{{
                          metric.key
                        }}</span>
                        <span class="container-primary-value">
                          <ng-container
                            *ngIf="metric.value !== null; else notAvailable">
                            <span>{{ metric.value }}</span>
                          </ng-container></span
                        >
                      </div>
                    }
                  </div>

                  @if (job.errors && job.errors.length > 0) {
                    <div class="error-container">
                      <span class="divider"></span>

                      <app-settings-section
                        [settingsTitle]="STRINGS_JOBS.errors.failed"
                        [settingsDescription]="
                          STRINGS_JOBS.errors.failedDescription
                        ">
                      </app-settings-section>

                      <table class="error-table">
                        <tr class="error-table-header">
                          <th class="error-table-buy-order-header">
                            <span class="buy-order-header">
                              {{ STRINGS_JOBS.errors.buyOrderId }}
                            </span>
                          </th>
                          <th>
                            <span class="buy-order-header">{{
                              STRINGS_JOBS.errors.errorDescription
                            }}</span>
                          </th>
                        </tr>
                        @for (
                          error of job.errors;
                          track error;
                          let idx = $index
                        ) {
                          <tr class="error-table-rows">
                            <td>
                              <span class="buy-order-column">{{
                                error.buyOrderId
                              }}</span>
                            </td>
                            <td>
                              <span class="description-column">{{
                                error.info
                              }}</span>
                            </td>
                          </tr>
                        }
                      </table>
                    </div>
                  }
                  @if (job.successes && job.successes.length > 0) {
                    <div class="error-container">
                      <span class="divider"></span>

                      <app-settings-section
                        [settingsTitle]="STRINGS_JOBS.successes.succeed"
                        [settingsDescription]="
                          STRINGS_JOBS.successes.succeedDescription
                        ">
                      </app-settings-section>

                      <table class="error-table">
                        <tr class="error-table-header">
                          <th class="error-table-buy-order-header">
                            <span class="buy-order-header">
                              {{ STRINGS_JOBS.successes.buyOrderId }}
                            </span>
                          </th>
                          <th>
                            <span class="buy-order-header">{{
                              STRINGS_JOBS.successes.remoteId
                            }}</span>
                          </th>
                        </tr>
                        @for (
                          error of job.successes;
                          track error;
                          let idx = $index
                        ) {
                          <tr class="error-table-rows">
                            <td>
                              <span class="buy-order-column">{{
                                error.buyOrderId
                              }}</span>
                            </td>
                            <td>
                              <span class="description-column">{{
                                error.info
                              }}</span>
                            </td>
                          </tr>
                        }
                      </table>
                    </div>
                  }
                } @else {
                  <div class="no-job-data-container">
                    <span>{{ STRINGS_JOBS.noData }}</span>
                  </div>
                }
              </div>
            </app-expansion-panel-jobs>
            <span class="jobs-divider"></span>
          } @else {
            <div class="job-row">
              <div class="job-name">{{ job.name }}</div>
              <div class="right-container">
                <div class="job-date">
                  {{
                    (job.status | jobStatus) === 'running'
                      ? (job.startDate | date: 'short')
                      : (job.lastUpdateDate | date: 'short')
                  }}
                </div>
                @switch (job.status | jobStatus) {
                  @case ('complete') {
                    <div class="job-status job-status-completed">
                      <mat-icon
                        class="status-icon"
                        svgIcon="check-xs"></mat-icon>
                      <p>{{ STRINGS_JOBS.complete }}</p>
                    </div>
                  }
                  @case ('queued') {
                    <div class="job-status job-status-queued">
                      <mat-icon class="status-icon" svgIcon="pause"></mat-icon>
                      <p>{{ STRINGS_JOBS.queued }}</p>
                    </div>
                  }
                  @case ('failed') {
                    <div class="job-status job-status-failed">
                      <mat-icon
                        class="status-icon"
                        svgIcon="cross-xs"></mat-icon>
                      <p>{{ STRINGS_JOBS.failed }}</p>
                    </div>
                  }
                  @default {
                    <div class="job-status job-status-running">
                      <app-progress-spinner
                        diameter="14"></app-progress-spinner>
                      <p>{{ STRINGS_JOBS.running }}</p>
                    </div>
                  }
                }
              </div>
            </div>
          }
        }
      </app-accordion>
    }
  </div>
}

<ng-template #notAvailable>
  <span>{{ NOT_AVAILABLE }}</span>
</ng-template>
