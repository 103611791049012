import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { select } from '@ngxs/store';
import {
  Job,
  LinkedSource,
} from 'src/app/core/api/integrations/model/integrations.model';
import { NOT_AVAILABLE_VALUE } from 'src/app/core/constants/global.constants';
import { STRINGS } from 'src/app/features/settings/components/model/settings.strings';

@Component({
  selector: 'app-source-jobs',
  templateUrl: './source-jobs.component.html',
  styleUrl: './source-jobs.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SourceJobsComponent {
  @Input() jobs: Job[];

  @Input() linkedSource: LinkedSource;

  readonly NOT_AVAILABLE = NOT_AVAILABLE_VALUE;

  readonly STRINGS_JOBS = STRINGS.sourceJobs;

  panelOpen = new Map<string, boolean>();

  borderedBottom(total: number, idx: number): boolean {
    return total - 1 > idx / 4;
  }

  getMetricsLength(metrics: any): number {
    return metrics ? Object.keys(metrics).length : 0;
  }
}
