import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  booleanAttribute,
  computed,
  input,
  output,
} from '@angular/core';
import { PageEventV2 } from '../paginator/paginator.component';

@Component({
  selector: 'app-datatable-container',
  templateUrl: './data-table-container.component.html',
  styleUrl: './data-table-container.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DatatableContainerComponent {
  size = input<'sm' | 'md' | 'lg'>('lg');

  withoutPagination = input(false, { transform: booleanAttribute });

  showFirstLastButtons = input(false, { transform: booleanAttribute });

  loading = input.required<boolean>();

  loadingPagination = input(false, { transform: booleanAttribute });

  loadingMessage = input<string>($localize`Loading contents...`);

  noDataMessage = input<string>($localize`There is no data available.`);

  page = input<number>(0);

  datasourceSize = input<number | null | undefined>();

  totalElements = input<number>(0);

  pageSizeOptions = input<number[]>([]);

  pageSize = input<number>(0);

  paginationChanged = output<PageEventV2>();

  tableContainerClasses = computed(() => [
    'table-container',
    `table-${this.size()}`,
  ]);
}
