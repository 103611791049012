export const STRINGS = {
  columns: {
    products: {
      SELECTION: {
        key: 'SELECTION',
        name: '',
        currency: false,
        containers: false,
      },
      SUPPLIER_CONNECTION: {
        key: 'SUPPLIER_CONNECTION',
        name: $localize`Supplier connection`,
        filterKey: 'WEBSHOP_PRODUCT_UUID',
        currency: true,
        containers: false,
      },
      CATEGORY: {
        key: 'CATEGORY',
        name: $localize`Category`,
        filterKey: 'CATEGORY',
        currency: false,
        containers: false,
      },
      XYZ_CATEGORY: {
        key: 'XYZ_CATEGORY',
        name: $localize`XYZ category`,
        filterKey: 'XYZ_CATEGORY',
        currency: false,
        containers: false,
      },
      NOVEL: {
        key: 'NOVEL',
        name: $localize`New product`,
        filterKey: 'NOVEL',
        currency: false,
        containers: false,
      },
      NAME: {
        key: 'NAME',
        name: $localize`Product name`,
        filterKey: 'NAME',
        currency: false,
        containers: false,
      },
      IGNORED: {
        key: 'IGNORED',
        name: $localize`Ignored`,
        filterKey: 'IGNORED',
        currency: false,
        containers: false,
      },
      ASSEMBLED: {
        key: 'ASSEMBLED',
        name: $localize`Assembled`,
        filterKey: 'ASSEMBLED',
        currency: false,
        containers: false,
      },
      NOT_BEING_BOUGHT: {
        key: 'NOT_BEING_BOUGHT',
        name: $localize`Phased Out`,
        filterKey: 'NOT_BEING_BOUGHT',
        currency: false,
        containers: false,
      },
      ATTRIBUTES: {
        key: 'ATTRIBUTES',
        name: $localize`Attributes`,
        filterKey: 'ATTRIBUTES',
        currency: false,
        containers: false,
      },
      ICONS: {
        key: 'ICONS',
        name: $localize`Attributes`,
        filterKey: 'ICONS',
        currency: false,
        containers: false,
      },
      SKU: {
        key: 'SKU',
        name: $localize`Product SKU`,
        filterKey: 'SKU',
        currency: false,
        containers: false,
      },
      SERVICE_LEVEL: {
        key: 'SERVICE_LEVEL',
        name: $localize`Service level`,
        filterKey: 'SERVICE_LEVEL',
        currency: false,
        containers: false,
      },
      STOCK_TIME: {
        key: 'STOCK_TIME',
        name: $localize`Stock time`,
        filterKey: 'STOCK_TIME',
        currency: false,
        containers: false,
      },
      FREE_STOCK: {
        key: 'FREE_STOCK',
        name: $localize`Free stock`,
        filterKey: 'FREE_STOCK',
        currency: false,
        containers: false,
      },
      EXPECTED_DEMAND: {
        key: 'EXPECTED_DEMAND',
        name: $localize`30-Day forecast`,
        filterKey: 'EXPECTED_DEMAND',
        currency: false,
        containers: false,
      },
      DEAD_STOCK: {
        key: 'DEAD_STOCK',
        name: $localize`Dead stock`,
        filterKey: 'DEAD_STOCK',
        currency: false,
        containers: false,
      },
      MANUAL_SERVICE_LEVEL: {
        key: 'MANUAL_SERVICE_LEVEL',
        name: $localize`Manual service level`,
        filterKey: 'MANUAL_SERVICE_LEVEL',
        currency: false,
        containers: false,
      },
      MINIMUM_STOCK: {
        key: 'MINIMUM_STOCK',
        name: $localize`Minimum stock level`,
        filterKey: 'MINIMUM_STOCK',
        currency: false,
        containers: false,
      },
      MAXIMUM_STOCK: {
        key: 'MAXIMUM_STOCK',
        name: $localize`Maximum stock level`,
        filterKey: 'MAXIMUM_STOCK',
        currency: false,
        containers: false,
        maximumStock: true,
      },
      EAN_CODE: {
        key: 'EAN_CODE',
        name: $localize`Product EAN code`,
        filterKey: 'EAN_CODE',
        currency: false,
        containers: false,
      },
      ARTICLE_CODE: {
        key: 'ARTICLE_CODE',
        name: $localize`Product article code`,
        filterKey: 'ARTICLE_CODE',
        currency: false,
        containers: false,
      },
      SALES_PRICE: {
        key: 'SALES_PRICE',
        name: $localize`Sales price`,
        unit: '€',
        filterKey: 'SALES_PRICE',
        currency: true,
        containers: false,
      },
      SUPPLIER_PRODUCT_PRICE: {
        key: 'SUPPLIER_PRODUCT_PRICE',
        name: $localize`Supplier product purchase price`,
        unit: '€',
        filterKey: 'SUPPLIER_PRODUCT_PRICE',
        currency: true,
        containers: false,
      },
      PROMOTION_NAME: {
        key: 'PROMOTION_NAME',
        name: $localize`Promotion name`,
        filterKey: 'PROMOTION_NAME',
        currency: false,
        containers: false,
        promotions: true,
      },
      PROMOTION_START_DATE: {
        key: 'PROMOTION_START_DATE',
        name: $localize`Promotion start date`,
        filterKey: 'PROMOTION_START_DATE',
        currency: false,
        containers: false,
        promotions: true,
      },
      PROMOTION_END_DATE: {
        key: 'PROMOTION_END_DATE',
        name: $localize`Promotion end date`,
        filterKey: 'PROMOTION_END_DATE',
        currency: false,
        containers: false,
        promotions: true,
      },
      NOTES: {
        key: 'NOTES',
        name: $localize`Notes`,
        filterKey: 'NOTES',
        currency: false,
        containers: false,
      },
      CREATED_AT_REMOTE: {
        key: 'CREATED_AT_REMOTE',
        name: $localize`Remote creation date`,
        filterKey: 'CREATED_AT_REMOTE',
        currency: false,
        containers: false,
      },
      ACTIONS: {
        key: 'ACTIONS',
        name: '',
        currency: false,
        containers: false,
      },
    },
    compositions: {
      NAME: {
        key: 'NAME',
        name: $localize`Product name`,
        filterKey: 'NAME',
        currency: false,
        containers: false,
      },
      SKU: {
        key: 'SKU',
        name: $localize`Product SKU`,
        filterKey: 'SKU',
        currency: false,
        containers: false,
      },
      EAN_CODE: {
        key: 'EAN_CODE',
        name: $localize`Product EAN code`,
        filterKey: 'EAN_CODE',
        currency: false,
        containers: false,
      },
      ARTICLE_CODE: {
        key: 'ARTICLE_CODE',
        name: $localize`Product article code`,
        filterKey: 'ARTICLE_CODE',
        currency: false,
        containers: false,
      },
      PART_QUANTITY: {
        key: 'PART_QUANTITY',
        name: $localize`Part quantity`,
        filterKey: 'PART_QUANTITY',
        currency: false,
        containers: false,
      },
    },
    productSuppliers: {
      SUPPLIER_PRODUCT_ACTIVE: {
        key: 'SUPPLIER_PRODUCT_ACTIVE',
        name: $localize`Active`,
        filterKey: 'SUPPLIER_PRODUCT_ACTIVE',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_PREFERRED: {
        key: 'SUPPLIER_PRODUCT_PREFERRED',
        name: $localize`Preferred`,
        filterKey: 'SUPPLIER_PRODUCT_PREFERRED',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_AVAILABLE: {
        key: 'SUPPLIER_PRODUCT_AVAILABLE',
        name: $localize`Available`,
        filterKey: 'SUPPLIER_PRODUCT_AVAILABLE',
        currency: false,
        containers: false,
      },
      AVAILABLE_DATE: {
        key: 'AVAILABLE_DATE',
        name: $localize`Availability date`,
        filterKey: 'AVAILABLE_DATE',
        currency: false,
        containers: false,
      },
      SUPPLIER_NAME: {
        key: 'SUPPLIER_NAME',
        name: $localize`Supplier name`,
        filterKey: 'SUPPLIER_NAME',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_NAME: {
        key: 'SUPPLIER_PRODUCT_NAME',
        name: $localize`Supplier product name`,
        filterKey: 'SUPPLIER_PRODUCT_NAME',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_SKU: {
        key: 'SUPPLIER_PRODUCT_SKU',
        name: $localize`Supplier product SKU`,
        filterKey: 'SUPPLIER_PRODUCT_SKU',
        currency: false,
        containers: false,
      },
      PURCHASE_IN_QUANTITIES_OF: {
        key: 'PURCHASE_IN_QUANTITIES_OF',
        name: $localize`Lot size`,
        filterKey: 'PURCHASE_IN_QUANTITIES_OF',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_EAN_CODE: {
        key: 'SUPPLIER_PRODUCT_EAN_CODE',
        name: $localize`Supplier product EAN`,
        filterKey: 'SUPPLIER_PRODUCT_EAN_CODE',
        currency: false,
        containers: false,
      },
      SUPPLIER_PRODUCT_ARTICLE_CODE: {
        key: 'SUPPLIER_PRODUCT_ARTICLE_CODE',
        name: $localize`Supplier product Article code`,
        filterKey: 'SUPPLIER_PRODUCT_ARTICLE_CODE',
        currency: false,
        containers: false,
      },
      MINIMUM_PURCHASE_QUANTITY: {
        key: 'MINIMUM_PURCHASE_QUANTITY',
        name: $localize`MOQ`,
        filterKey: 'MINIMUM_PURCHASE_QUANTITY',
        currency: false,
        containers: false,
      },
      LEAD_TIME: {
        key: 'LEAD_TIME',
        name: $localize`Product lead time (days)`,
        filterKey: 'LEAD_TIME',
        currency: false,
        containers: false,
      },
      WEIGHT: {
        key: 'WEIGHT',
        name: $localize`Weight`,
        filterKey: 'WEIGHT',
        currency: false,
        containers: true,
      },
      VOLUME: {
        key: 'VOLUME',
        name: $localize`Volume`,
        filterKey: 'VOLUME',
        currency: false,
        containers: true,
      },
      PRICE: {
        key: 'PRICE',
        name: $localize`Purchase price`,
        filterKey: 'PRICE',
        currency: false,
        containers: true,
      },
      ACTIONS: {
        key: 'ACTIONS',
        name: null,
        currency: false,
        containers: false,
      },
    },
  },
  tooltips: {
    linkProduct: {
      phaseOutLinked: $localize`We advise phasing out the predecessor, so this product will not be kept in stock`,
      hasPredecessor: $localize`This product has a predecessor`,
    },
    supplyChainInformation: {
      stockPosition: {
        timeDependent: $localize`Free stock plus expected deliveries in cycle`,
        notTimeDependent: $localize`Free stock plus all expected deliveries`,
      },
      safetyStock: $localize`The safety stock depends on your desired service level settings, which can sometimes result in negative values.`,
      deadStock: $localize`Estimated dead stock value based on the current free stock and the 30-day forecast.`,
    },
    sales: {
      lastMonth: $localize`Last month`,
      lastThreeMonths: $localize`Last 3 months`,
      lastSixMonths: $localize`Last 6 months`,
      lastYear: $localize`Last year`,
      all: $localize`All data`,
      last5Years: $localize`Last 5 years`,
      groupedDaily: $localize`Grouped daily`,
      groupedWeekly: $localize`Grouped weekly`,
      groupedMonthly: $localize`Grouped monthly`,
      refresh: $localize`Refresh`,
    },
    supplierProduct: {
      preferred: $localize`Preferred`,
      available: $localize`Available`,
      notAvailable: $localize`Not available`,
    },
  },
  notifications: {
    predecessorRedirect: $localize`Viewing predecessor product`,
    successorRedirect: $localize`Viewing successor product`,
  },
  metadata: {
    title: $localize`Products`,
    linkPredecessor: $localize`Link predecessor`,
    editPredecessor: $localize`Edit predecessor`,
    products: {
      actions: {
        addToPromotion: $localize`Add to promotion`,
        phaseOut: $localize`Phase out...`,
        doNotPhaseOut: $localize`Do not phase out`,
        ignore: $localize`Ignore`,
        unignore: $localize`Unignore`,
      },
    },
    productSuppliers: {
      actions: {
        add: $localize`Add new...`,
        edit: $localize`Edit`,
        remove: $localize`Remove`,
      },
    },
    dialogs: {
      phaseOut: {
        title: $localize`Phase out`,
        indefinitely: $localize`Indefinitely`,
        until: $localize`Until`,
        action: {
          save: $localize`Phase out`,
        },
      },
      linkPredecessor: {
        title: $localize`Link predecessor product`,
        description: $localize`Connect a predecessor product to this product to merge sales history`,
        phaseOutLinked: $localize`Phase out predecessor`,
        placeholder: $localize`Select a product`,
      },
    },
    warnings: {
      assembled: $localize`This is an assembled product and will not be purchased.`,
      ignored: $localize`This product is marked as ignored and will not be considered when purchasing.`,
    },
    overview: {
      title: $localize`Product details`,
      category: $localize`Category`,
      productName: $localize`Product name`,
      sku: $localize`SKU`,
      serviceLevel: $localize`Service level`,
      freeStock: $localize`Free stock`,
      demandDescription: $localize`Expected demand in the coming 30 days`,
      notesInfo: {
        title: $localize`Notes`,
      },
      predecessor: $localize`Predecessor`,
      successor: $localize`Successor`,
      activeSupplier: $localize`Active supplier`,
      noSupplier: $localize`No active supplier`,
    },
    settings: {
      title: $localize`Product settings`,
      ignore: $localize`Ignore product`,
      ignoreDescription: $localize`Ignore this product and it will not be considered when purchasing.`,
      freeStockLevel: $localize`Manual stock constraints`,
      freeStockLevelDescription: $localize`Set manual stock constraints for this product.`,
      minimumStockLevel: $localize`Minimum stock level`,
      maximumStock: $localize`Maximum stock level`,
      phaseOut: $localize`Phase out`,
      phaseOutDescription: $localize`Stop purchasing this product.`,
      phaseOutEndDate: $localize`End date`,
      phaseOutEndDateDescription: $localize`Select a date after which this product should be considered for purchasing.`,
      manualServiceLevel: $localize`Manual service level`,
      manualServiceLevelDescription: $localize`Set manual service level for this product.`,
    },
    supplyChainInformation: {
      title: $localize`Supply chain information`,
      activeSupplier: {
        title: $localize`Order cycle`,
        noSupplier: $localize`No active supplier`,
        deliveryTime: $localize`Lead time (days)`,
        productLeadTime: $localize`Product lead time (days)`,
        reorderPeriod: $localize`Reorder period (days)`,
        cycle: $localize`Cycle (days)`,
      },
      advice: {
        title: $localize`Purchase advice`,
        forecastCycle: $localize`Forecast (cycle)`,
        forecastDays: $localize`Forecast (30 days)`,
        safetyStock: $localize`Safety stock`,
        maxStock: $localize`Order up to`,
        stockPosition: $localize`Stock position`,
      },
      product: {
        title: $localize`Product`,
        purchasePrice: $localize`Purchasing price`,
        salesPrice: $localize`Sales price`,
        margin: $localize`Margin`,
        freeStock: $localize`Free stock`,
      },
    },
    sales: {
      title: $localize`Sales history`,
      total: $localize`Total sales:`,
      states: {
        loading: $localize`Loading sales information`,
      },
    },
    tabs: {
      supplyChain: $localize`Supply chain info`,
      productInformation: $localize`Product info`,
      suppliers: $localize`Suppliers`,
      deliveries: $localize`Deliveries`,
      promotions: $localize`Promotions`,
      compositions: $localize`Compositions`,
      parts: $localize`Parts`,
      sales: $localize`Product history`,
    },
    states: {
      loading: $localize`Loading contents...`,
      noData: $localize`There is no data available.`,
    },
    relation: {
      title: $localize`Product relations`,
      predecessor: $localize`Predecessor`,
      successor: $localize`Successor`,
      activeSupplier: $localize`Active supplier`,
    },
  },
};
