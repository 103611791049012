export const STRINGS = {
  columns: {
    promotions: {
      SELECTION: {
        key: 'SELECTION',
        name: '',
        currency: false,
        containers: false,
      },
      NAME: {
        key: 'NAME',
        name: $localize`Promotion Name`,
        filterKey: 'NAME',
        currency: false,
        containers: false,
      },
      START_DATE: {
        key: 'START_DATE',
        name: $localize`Start date`,
        filterKey: 'START_DATE',
        currency: false,
        containers: false,
      },
      END_DATE: {
        key: 'END_DATE',
        name: $localize`End date`,
        filterKey: 'END_DATE',
        currency: false,
        containers: false,
      },
      UPLIFT_TYPE: {
        key: 'UPLIFT_TYPE',
        name: $localize`Uplift type`,
        filterKey: 'UPLIFT_TYPE',
        currency: false,
        containers: false,
      },
      EXPECTED_UPLIFT: {
        key: 'EXPECTED_UPLIFT',
        name: $localize`Expected uplift`,
        filterKey: 'EXPECTED_UPLIFT',
        currency: false,
        containers: false,
      },
      NUMBER_OF_PRODUCTS: {
        key: 'NUMBER_OF_PRODUCTS',
        name: $localize`Number of products`,
        filterKey: 'NUMBER_OF_PRODUCTS',
        currency: false,
        containers: false,
      },
      NOTES: {
        key: 'NOTES',
        name: $localize`Notes`,
        filterKey: 'NOTES',
        currency: false,
        containers: false,
      },
      ACTIONS: {
        key: 'ACTIONS',
        name: '',
        currency: false,
        containers: false,
      },
    },
    promotionsWebshopProducts: {
      SELECTION: {
        key: 'SELECTION',
        name: '',
        currency: false,
        containers: false,
      },
      CATEGORY: {
        key: 'CATEGORY',
        name: $localize`Category`,
        filterKey: 'CATEGORY',
        currency: false,
        containers: false,
      },
      XYZ_CATEGORY: {
        key: 'XYZ_CATEGORY',
        name: $localize`XYZ category`,
        filterKey: 'XYZ_CATEGORY',
        currency: false,
        containers: false,
      },
      NAME: {
        key: 'NAME',
        name: $localize`Product name`,
        filterKey: 'NAME',
        currency: false,
        containers: false,
      },
      SKU: {
        key: 'SKU',
        name: $localize`Product SKU`,
        filterKey: 'SKU',
        currency: false,
        containers: false,
      },
      SPECIFIC_UPLIFT_TYPE: {
        key: 'SPECIFIC_UPLIFT_TYPE',
        name: $localize`Uplift type`,
        filterKey: 'SPECIFIC_UPLIFT_TYPE',
        currency: false,
        containers: false,
      },
      SPECIFIC_UPLIFT_INCREASE: {
        key: 'SPECIFIC_UPLIFT_INCREASE',
        name: $localize`Expected specific uplift`,
        filterKey: 'SPECIFIC_UPLIFT_INCREASE',
        currency: false,
        containers: false,
      },
      SERVICE_LEVEL: {
        key: 'SERVICE_LEVEL',
        name: $localize`Service level`,
        filterKey: 'SERVICE_LEVEL',
        currency: false,
        containers: false,
      },
      STOCK_TIME: {
        key: 'STOCK_TIME',
        name: $localize`Stock time`,
        filterKey: 'STOCK_TIME',
        currency: false,
        containers: false,
      },
      FREE_STOCK: {
        key: 'FREE_STOCK',
        name: $localize`Free stock`,
        filterKey: 'FREE_STOCK',
        currency: false,
        containers: false,
      },
      EXPECTED_DEMAND: {
        key: 'EXPECTED_DEMAND',
        name: $localize`Expected demand (30 days)`,
        filterKey: 'EXPECTED_DEMAND',
        currency: false,
        containers: false,
      },
      EAN_CODE: {
        key: 'EAN_CODE',
        name: $localize`Product EAN code`,
        filterKey: 'EAN_CODE',
        currency: false,
        containers: false,
      },
      ARTICLE_CODE: {
        key: 'ARTICLE_CODE',
        name: $localize`Product article code`,
        filterKey: 'ARTICLE_CODE',
        currency: false,
        containers: false,
      },
      PRICE: {
        key: 'PRICE',
        name: $localize`Price`,
        filterKey: 'PRICE',
        currency: true,
        containers: false,
      },
      NOTES: {
        key: 'NOTES',
        name: $localize`Notes`,
        filterKey: 'NOTES',
        currency: false,
        containers: false,
      },
      ACTIONS: {
        key: 'ACTIONS',
        name: '',
        currency: false,
        containers: false,
      },
    },
    webshopProductPromotions: {
      SELECTION: {
        key: 'SELECTION',
        name: '',
        currency: false,
        containers: false,
      },
      NAME: {
        key: 'NAME',
        name: $localize`Promotion Name`,
        filterKey: 'NAME',
        currency: false,
        containers: false,
      },
      START_DATE: {
        key: 'START_DATE',
        name: $localize`Start date`,
        filterKey: 'START_DATE',
        currency: false,
        containers: false,
      },
      END_DATE: {
        key: 'END_DATE',
        name: $localize`End date`,
        filterKey: 'END_DATE',
        currency: false,
        containers: false,
      },
      EXPECTED_UPLIFT: {
        key: 'EXPECTED_UPLIFT',
        name: $localize`Expected uplift`,
        filterKey: 'EXPECTED_UPLIFT',
        currency: false,
        containers: false,
      },
      PRODUCT_UPLIFT_TYPE: {
        key: 'PRODUCT_UPLIFT_TYPE',
        name: $localize`Product uplift type`,
        filterKey: 'PRODUCT_UPLIFT_TYPE',
        currency: false,
        containers: false,
      },
      PRODUCT_UPLIFT: {
        key: 'PRODUCT_UPLIFT',
        name: $localize`Product uplift`,
        filterKey: 'PRODUCT_UPLIFT',
        currency: false,
        containers: false,
      },
      NUMBER_OF_PRODUCTS: {
        key: 'NUMBER_OF_PRODUCTS',
        name: $localize`Number of products`,
        filterKey: 'NUMBER_OF_PRODUCTS',
        currency: false,
        containers: false,
      },
      ENABLED: {
        key: 'ENABLED',
        name: $localize`Enabled`,
        filterKey: 'ENABLED',
        currency: false,
        containers: false,
      },
      ACTIONS: {
        key: 'ACTIONS',
        name: '',
        currency: false,
        containers: false,
      },
    },
  },
  tooltips: {
    required: $localize`This field is required`,
  },
  notifications: {},
  metadata: {
    title: $localize`Promotions`,
    promotions: {
      uplifts: {
        relativeTitle: $localize`Relative (%)`,
        relative: $localize`Relative`,
        absolute: $localize`Absolute`,
        closeOut: $localize`Close out`,
        noUplift: $localize`No uplift`,
      },
      actions: {
        create: $localize`Create promotion`,
        edit: $localize`Edit`,
        disable: $localize`Disable`,
        enable: $localize`Enable`,
        delete: $localize`Delete`,
        remove: $localize`Remove`,
        addNew: $localize`Add new...`,
      },
    },
    promotionEditor: {
      title: $localize`Promotions`,
      actions: {
        addProducts: $localize`Add product(s)`,
        disablePromotion: $localize`Disable promotion`,
        enablePromotion: $localize`Enable promotion`,
        deletePromotion: $localize`Delete promotion`,
        editPromotion: $localize`Edit promotion`,
        edit: $localize`Edit`,
        remove: $localize`Remove`,
        saveChanges: $localize`Save changes`,
        applyEntire: $localize`Add to entire webshop`,
      },
      overview: {
        promotionInfo: {
          title: $localize`Promotion info`,
          promotionName: $localize`Promotion name`,
          upliftType: $localize`Uplift type`,
          expectedUplift: $localize`Expected uplift`,
          products: $localize`Products`,
          startDate: $localize`Start date`,
          endDate: $localize`End date`,
          status: $localize`Status`,
          statusOptions: {
            ENDED: $localize`Ended`,
            PLANNED: $localize`Planned`,
            IN_PROGRESS: $localize`In progress`,
            DISABLED: $localize`Disabled`,
          },
        },
        notesInfo: {
          title: $localize`Notes`,
          placeholder: $localize`No notes filled in for this supplier. You can edit them on the supplier page.`,
        },
        totalProductsInfo: {
          title: $localize`Total products`,
        },
        statusInfo: {
          title: $localize`Status`,
        },
      },
      tabs: {
        info: $localize`Promotion info`,
        products: $localize`Promotion products`,
      },
    },
    addProductPromotionDialog: {
      title: $localize`Add product(s) to promotion`,
      promotion: {
        title: $localize`Promotion`,
        selectPromotion: $localize`Select a promotion`,
      },
      promotionDetails: {
        title: $localize`Promotion details`,
        name: $localize`Promotion name`,
        startDate: $localize`Start date`,
        endDate: $localize`End date`,
      },
      uplift: {
        title: $localize`Uplift`,
        type: $localize`Uplift type`,
        increase: $localize`Expected uplift`,
        selectUplift: $localize`Select an uplift type`,
      },
      specificUplift: {
        title: $localize`Specific uplift (Optional)`,
      },
      settings: {
        title: $localize`Settings`,
        enabled: $localize`Enabled`,
      },
    },
    states: {
      loading: $localize`Loading contents...`,
      noData: $localize`There is no data available.`,
    },
  },
};
