<app-filter-container-template
  [headerTemplate]="headerTemplate()"
  [footerTemplate]="footerTemplate()"
  [filterName]="filterName()"
  [disableClear]="!filterStrategy?.hasValue() || filterDisabled()"
  [disableApply]="
    filterStrategy === null ||
    !filterStrategy?.hasValue() ||
    filterStrategy?.hasSameValue(filterValues()?.value) ||
    filterDisabled()
  "
  [showApplyAll]="showApplyAll()"
  (apply)="apply($event)"
  (clear)="clear()"
  (cancel)="cancel()">
  <app-filter-text-equals-v2 name="equals" [(ngModel)]="filterStrategy.form" />
</app-filter-container-template>
