import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators';
import {
  LoadFailed,
  Loading,
  LoadSucceed,
  SaveFailed,
  SaveSucceed,
  Saving,
} from 'src/app/core/actions/app.action';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { WebshopState } from 'src/app/core/states/webshop.state';
import {
  ResetEmailTemplates,
  SetChangesValidity,
  SetDefaultTemplate,
  UpdateTemplateBody,
  UpdateTemplateLanguage,
  UpdateTemplateSubject,
} from 'src/app/shared/components/email-templates-v2/actions/email-templates-v2.actions';
import {
  EmailTemplateChanged,
  EmailTemplateLanguageOption,
  EmailTemplateModel,
  TEMPLATE_LANGUAGES_TRANSLATIONS,
} from 'src/app/shared/components/email-templates-v2/model/email-templates-v2.model';
import {
  GetTemplatesByContext,
  LoadAccountEmailTemplates,
  LoadSupplierEmailTemplates,
  LoadWebshopEmailTemplates,
  SaveAccountTemplate,
  SaveEmailTemplate,
  SaveSupplierTemplate,
  SaveWebshopTemplate,
  SetAndGetTemplatesByContext,
  SetTemplatesContext,
} from '../actions/email-templates.action';
import {
  EmailTemplate,
  EmailTemplateContexts,
  EmailTemplateLanguage,
  EmailTemplateLanguages,
  EmailTemplates,
  EmailTemplatesContext,
  EmailTemplatesContexts,
  EmailTemplateTypes,
  UpsertTemplatePayload,
} from 'src/app/shared/models/emails/v1/emails.model';
import { EmailsService } from 'src/app/core/api/emails/v1/emails.service';
import {
  SetInitialData,
  SetPendingChanges,
} from 'src/app/core/actions/pending-changes.action';
import { PendingChangesKeys } from 'src/app/shared/models/navigation/pending-changes.model';

export interface EmailTemplatesStateModel {
  loading: boolean;
  valid: boolean;
  defaultLanguage: EmailTemplateLanguage;
  currentContext: EmailTemplatesContext | null;
  templatesContexts: EmailTemplatesContexts;
  emailTemplates: EmailTemplate[];
  emailTemplateModel: EmailTemplateModel;
}

const defaultTemplateModel: EmailTemplateModel = {
  language: EmailTemplateLanguages.EN,
  subject: '',
  body: '',
  type: EmailTemplateTypes.SUPPLIER,
  isDefault: false,
};

@State<EmailTemplatesStateModel>({
  name: 'emailTemplatesState',
  defaults: {
    loading: true,
    valid: false,
    defaultLanguage: EmailTemplateLanguages.EN,
    currentContext: null,
    templatesContexts: {
      [EmailTemplateContexts.SUPPLIER]: [],
      [EmailTemplateContexts.ACCOUNT_SUPPLIER]: [],
      [EmailTemplateContexts.WEBSHOP]: [],
    },
    emailTemplates: [],
    emailTemplateModel: defaultTemplateModel,
  },
})
@Injectable()
export class EmailTemplatesState {
  private readonly store = inject(Store);
  private readonly emailService = inject(EmailsService);

  @Selector()
  static defaultLanguage(
    state: EmailTemplatesStateModel
  ): EmailTemplateLanguage {
    return state.defaultLanguage;
  }

  @Selector()
  static emailTemplates(state: EmailTemplatesStateModel): EmailTemplate[] {
    return state.emailTemplates;
  }

  @Selector()
  static hasEmailTemplates(state: EmailTemplatesStateModel): boolean {
    return Object.values(state.templatesContexts).some(
      templates => !!templates.length
    );
  }

  @Selector()
  static emailTemplateByLanguage(state: EmailTemplatesStateModel) {
    return (language: string): EmailTemplate | null => {
      return (
        state.emailTemplates?.find(
          template => template.language === language
        ) || null
      );
    };
  }

  @Selector()
  public static defaultLanguageTemplate(
    state: EmailTemplatesStateModel
  ): EmailTemplate | null {
    if (!!state?.emailTemplates) {
      return state.emailTemplates?.find(
        (emailTemplate: EmailTemplate) =>
          emailTemplate.language === state.defaultLanguage
      );
    }

    return null;
  }

  @Selector([EmailTemplatesState.emailTemplates])
  static availableTemplatesLanguages(
    templates: EmailTemplate[]
  ): EmailTemplateLanguageOption[] {
    return (
      templates?.map((template: EmailTemplate) => {
        return {
          key: TEMPLATE_LANGUAGES_TRANSLATIONS[template.language],
          value: template.language as EmailTemplateLanguage,
        };
      }) || []
    );
  }

  @Selector()
  public static currentTemplateModel(
    state: EmailTemplatesStateModel
  ): EmailTemplateModel {
    return state.emailTemplateModel;
  }

  @Selector()
  public static currentTemplateLanguage(
    state: EmailTemplatesStateModel
  ): string {
    return state.emailTemplateModel.language;
  }

  @Selector()
  public static currentTemplateSubject(
    state: EmailTemplatesStateModel
  ): string {
    return state.emailTemplateModel.subject;
  }

  @Selector()
  public static currentTemplateBody(state: EmailTemplatesStateModel): string {
    return state.emailTemplateModel.body;
  }

  @Selector()
  public static currentTemplateIsDefault(
    state: EmailTemplatesStateModel
  ): boolean {
    return state.defaultLanguage === state.emailTemplateModel.language;
  }

  @Selector()
  public static isValidTemplate(state: EmailTemplatesStateModel): boolean {
    return state.valid;
  }

  @Selector()
  public static currentTemplateChanges(
    state: EmailTemplatesStateModel
  ): EmailTemplateChanged {
    return {
      type: state.emailTemplateModel.type,
      language: state.emailTemplateModel.language,
      defaultTemplate: state.emailTemplateModel.isDefault,
      subject: state.emailTemplateModel.subject,
      body: state.emailTemplateModel.body,
      validChanges: state.valid,
    };
  }

  @Selector()
  public static availableTemplatesContexts(
    state: EmailTemplatesStateModel
  ): string[] {
    return Object.entries(state.templatesContexts)
      .filter(([_, templates]) => !!templates.length)
      .map(([key]) => key);
  }

  @Selector()
  public static loadingTemplates(state: EmailTemplatesStateModel): boolean {
    return state.loading;
  }

  @Action(LoadWebshopEmailTemplates, { cancelUncompleted: true })
  loadWebshopEmailTemplates(
    ctx: StateContext<EmailTemplatesStateModel>,
    payload: LoadWebshopEmailTemplates
  ) {
    ctx.setState(
      patch({
        loading: true,
      })
    );

    return ctx.dispatch(new Loading(false)).pipe(
      switchMap(() => this._findWebshopTemplates(ctx, payload.type)),
      tap((emailTemplates: EmailTemplates) => {
        ctx.setState(
          patch({
            loading: false,
            defaultLanguage:
              payload.preferredLanguage.toUpperCase() as EmailTemplateLanguage,
            templatesContexts: patch({
              [EmailTemplateContexts.WEBSHOP]: emailTemplates.templates,
            }),
          })
        );
      }),
      concatMap(() => ctx.dispatch(new LoadSucceed(false)))
    );
  }

  @Action(LoadAccountEmailTemplates, { cancelUncompleted: true })
  loadAccountEmailTemplates(
    ctx: StateContext<EmailTemplatesStateModel>,
    payload: LoadAccountEmailTemplates
  ) {
    ctx.setState(
      patch({
        loading: true,
      })
    );

    return ctx.dispatch(new Loading(false)).pipe(
      switchMap(() => this._findAccountTemplates(ctx, payload.type)),
      tap((emailTemplates: EmailTemplates) => {
        ctx.setState(
          patch({
            loading: false,
            defaultLanguage:
              payload.preferredLanguage.toUpperCase() as EmailTemplateLanguage,
            templatesContexts: patch({
              [EmailTemplateContexts.ACCOUNT_SUPPLIER]:
                emailTemplates.templates,
            }),
          })
        );
      }),
      concatMap(() => ctx.dispatch(new LoadSucceed(false)))
    );
  }

  @Action(LoadSupplierEmailTemplates, { cancelUncompleted: true })
  loadSupplierEmailTemplates(
    ctx: StateContext<EmailTemplatesStateModel>,
    payload: LoadSupplierEmailTemplates
  ) {
    ctx.setState(
      patch({
        loading: true,
      })
    );

    return ctx.dispatch(new Loading(false)).pipe(
      switchMap(() => this._findSupplierTemplates(ctx, payload.supplierUuid)),
      tap((emailTemplates: EmailTemplates) => {
        ctx.setState(
          patch({
            loading: false,
            defaultLanguage:
              payload.preferredLanguage.toUpperCase() as EmailTemplateLanguage,
            templatesContexts: patch({
              [EmailTemplateContexts.SUPPLIER]: emailTemplates.templates,
            }),
          })
        );
      }),
      concatMap(() => ctx.dispatch(new LoadSucceed(false)))
    );
  }

  @Action(SetTemplatesContext, { cancelUncompleted: true })
  setTemplatesContext(
    ctx: StateContext<EmailTemplatesStateModel>,
    payload: SetTemplatesContext
  ) {
    ctx.setState(
      patch({
        currentContext: payload.context,
      })
    );
  }

  @Action(GetTemplatesByContext)
  getTemplatesByContext(ctx: StateContext<EmailTemplatesStateModel>) {
    const state = ctx.getState();

    const contextEmailTemplates = state.templatesContexts[state.currentContext];

    ctx.setState(
      patch({
        emailTemplates: contextEmailTemplates,
      })
    );

    const defaultLanguage = state.defaultLanguage;

    const template = this._getTemplateByLanguageOrTheRemain(
      contextEmailTemplates,
      state.emailTemplateModel.language ||
        (defaultLanguage as EmailTemplateLanguage)
    );

    if (!defaultLanguage || !template) {
      this._applyDefaults(ctx, contextEmailTemplates, defaultLanguage);

      return;
    }

    this._applyPreferredLanguageTemplate(
      ctx,
      template,
      defaultLanguage as EmailTemplateLanguage
    );
  }

  @Action(SetAndGetTemplatesByContext, { cancelUncompleted: true })
  setAndGetTemplatesByContext(
    ctx: StateContext<EmailTemplatesStateModel>,
    payload: SetAndGetTemplatesByContext
  ) {
    return ctx
      .dispatch(new SetTemplatesContext(payload.context))
      .pipe(concatMap(() => ctx.dispatch(new GetTemplatesByContext())));
  }

  @Action(SetChangesValidity, { cancelUncompleted: true })
  setChangesValidity(
    ctx: StateContext<EmailTemplatesStateModel>,
    payload: SetChangesValidity
  ) {
    ctx.patchState({
      valid: payload.validChanges,
    });
  }

  @Action(SetDefaultTemplate, { cancelUncompleted: true })
  setDefaultTemplate(ctx: StateContext<EmailTemplatesStateModel>) {
    ctx.setState(
      patch({
        defaultLanguage: ctx.getState().emailTemplateModel.language,
        emailTemplateModel: patch({
          isDefault: true,
        }),
      })
    );

    return this._setPendingChanges(ctx);
  }

  @Action(UpdateTemplateLanguage, { cancelUncompleted: true })
  updateTemplateLanguage(
    ctx: StateContext<EmailTemplatesStateModel>,
    payload: UpdateTemplateLanguage
  ) {
    const state = ctx.getState();

    const defaultTemplate: EmailTemplate = {
      language: payload.language,
      subject: '',
      body: '',
    };

    const template =
      this._getTemplateByLanguage(state.emailTemplates, payload.language) ||
      defaultTemplate;

    ctx.setState(
      patch({
        emailTemplateModel: patch({
          language: payload.language,
          subject: template.subject,
          body: template.body,
          isDefault: state.defaultLanguage === payload.language,
        }),
      })
    );
  }

  @Action(UpdateTemplateSubject, { cancelUncompleted: true })
  updateTemplateSubject(
    ctx: StateContext<EmailTemplatesStateModel>,
    payload: UpdateTemplateSubject
  ) {
    ctx.setState(
      patch({
        emailTemplateModel: patch({
          subject: payload.subject,
        }),
      })
    );

    return this._setPendingChanges(ctx);
  }

  @Action(UpdateTemplateBody, { cancelUncompleted: true })
  updateTemplateBody(
    ctx: StateContext<EmailTemplatesStateModel>,
    payload: UpdateTemplateBody
  ) {
    ctx.setState(
      patch({
        emailTemplateModel: patch({
          body: payload.body,
        }),
      })
    );

    return this._setPendingChanges(ctx);
  }

  @Action(ResetEmailTemplates)
  resetEmailTemplates(ctx: StateContext<EmailTemplatesStateModel>) {
    ctx.patchState({
      loading: true,
      valid: false,
      defaultLanguage: EmailTemplateLanguages.EN,
      currentContext: null,
      templatesContexts: {
        [EmailTemplateContexts.SUPPLIER]: [],
        [EmailTemplateContexts.ACCOUNT_SUPPLIER]: [],
        [EmailTemplateContexts.WEBSHOP]: [],
      },
      emailTemplates: [],
      emailTemplateModel: defaultTemplateModel,
    });
  }

  @Action(SaveWebshopTemplate)
  saveWebshopTemplate(ctx: StateContext<EmailTemplatesStateModel>) {
    return ctx.dispatch(new Saving(false)).pipe(
      switchMap(() => this._saveWebshopTemplate(ctx)),
      concatMap(() => ctx.dispatch(new SaveSucceed(false)))
    );
  }

  @Action(SaveAccountTemplate)
  saveAccountTemplate(ctx: StateContext<EmailTemplatesStateModel>) {
    return ctx.dispatch(new Saving(false)).pipe(
      switchMap(() => this._saveAccountTemplate(ctx)),
      concatMap(() => ctx.dispatch(new SaveSucceed(false)))
    );
  }

  @Action(SaveSupplierTemplate)
  saveSupplierTemplate(
    ctx: StateContext<EmailTemplatesStateModel>,
    payload: SaveSupplierTemplate
  ) {
    return ctx.dispatch(new Saving(false)).pipe(
      switchMap(() => this._saveSupplierTemplate(ctx, payload.supplierUuid)),
      concatMap(() => ctx.dispatch(new SaveSucceed(false)))
    );
  }

  private _getTemplateByLanguage(
    templates: EmailTemplate[],
    language: EmailTemplateLanguage
  ): EmailTemplate | null {
    if (!templates) return null;

    return (
      templates.find(
        (template: EmailTemplate) => template.language === language
      ) || null
    );
  }

  private _getTemplateByLanguageOrTheRemain(
    templates: EmailTemplate[],
    language: EmailTemplateLanguage
  ): EmailTemplate | null {
    if (!templates) return null;

    const templateFromLanguage =
      templates.find(
        (template: EmailTemplate) => template.language === language
      ) || null;

    if (templateFromLanguage) return templateFromLanguage;

    const remainTemplate = templates[0];

    if (remainTemplate) return remainTemplate;

    return null;
  }

  /**
   * Gets default language and template based on user language
   * @param templates
   * @param language
   * @returns defaults
   */
  private _getDefaults(
    templates: EmailTemplate[],
    language: string
  ): {
    defaultLanguage: EmailTemplateLanguage;
    defaultTemplate: EmailTemplate | null;
  } {
    if (['en-US', 'nl-NL'].includes(language)) {
      const [locale] = language.split('-');

      return {
        defaultLanguage: locale.toUpperCase() as EmailTemplateLanguage,
        defaultTemplate:
          templates?.find(
            (template: EmailTemplate) => template.language === locale
          ) || null,
      };
    }

    return {
      defaultLanguage: EmailTemplateLanguages.DE,
      defaultTemplate:
        templates?.find(
          (template: EmailTemplate) =>
            template.language === EmailTemplateLanguages.DE
        ) || null,
    };
  }

  private _applyDefaults(
    ctx: StateContext<EmailTemplatesStateModel>,
    emailTemplates: EmailTemplate[],
    languageProvided: EmailTemplateLanguage
  ): void {
    const language = this.store.selectSnapshot(AccountSettingsState.localeId);

    const { defaultLanguage, defaultTemplate } = this._getDefaults(
      emailTemplates,
      language
    );

    if (!defaultTemplate) {
      const initialLanguage = languageProvided || EmailTemplateLanguages.EN;

      const initialTemplate = {
        ...defaultTemplateModel,
        language: initialLanguage,
      };

      ctx.patchState({
        defaultLanguage: initialLanguage,
        emailTemplateModel: initialTemplate,
      });

      ctx.dispatch(
        new SetInitialData(PendingChangesKeys.SETTINGS_EMAIL_TEMPLATES, {
          language: initialTemplate.language,
          defaultTemplate: false,
          subject: initialTemplate.subject,
          body: initialTemplate.body,
        })
      );

      return;
    }

    ctx.patchState({
      defaultLanguage: defaultLanguage,
      emailTemplateModel: {
        ...ctx.getState().emailTemplateModel,
        language: defaultTemplate.language,
        subject: defaultTemplate.subject,
        body: defaultTemplate.body,
      },
    });

    ctx.dispatch(
      new SetInitialData(PendingChangesKeys.SETTINGS_EMAIL_TEMPLATES, {
        language: defaultTemplate.language,
        defaultTemplate: true,
        subject: defaultTemplate.subject,
        body: defaultTemplate.body,
      })
    );
  }

  private _applyPreferredLanguageTemplate(
    ctx: StateContext<EmailTemplatesStateModel>,
    template: EmailTemplate,
    preferredLanguage: string
  ): void {
    ctx.patchState({
      defaultLanguage: preferredLanguage as EmailTemplateLanguage,
      emailTemplateModel: {
        ...ctx.getState().emailTemplateModel,
        isDefault: template.language === preferredLanguage,
        language: template.language,
        subject: template.subject,
        body: template.body,
      },
    });

    ctx.dispatch(
      new SetInitialData(PendingChangesKeys.SETTINGS_EMAIL_TEMPLATES, {
        language: template.language,
        defaultTemplate: template.language === preferredLanguage,
        subject: template.subject,
        body: template.body,
      })
    );
  }

  private _setPendingChanges(
    ctx: StateContext<EmailTemplatesStateModel>
  ): void {
    ctx.dispatch(
      new SetPendingChanges(
        PendingChangesKeys.SETTINGS_EMAIL_TEMPLATES,
        ctx.getState().valid,
        {
          language: ctx.getState().emailTemplateModel.language,
          defaultTemplate: ctx.getState().emailTemplateModel.isDefault,
          subject: ctx.getState().emailTemplateModel.subject,
          body: ctx.getState().emailTemplateModel.body,
        }
      )
    );
  }

  private _findWebshopTemplates(
    ctx: StateContext<EmailTemplatesStateModel>,
    type: EmailTemplateTypes
  ): Observable<EmailTemplates> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.emailService.findWebshopTemplates(webshopUuid, { type }).pipe(
      catchError(e => {
        ctx.setState(
          patch({
            loading: false,
          })
        );

        ctx.dispatch(new LoadFailed());

        throw new Error(e.message || e);
      })
    );
  }

  private _findAccountTemplates(
    ctx: StateContext<EmailTemplatesStateModel>,
    type: EmailTemplateTypes
  ): Observable<EmailTemplates> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.emailService.findAccountTemplates(webshopUuid, { type }).pipe(
      catchError(e => {
        ctx.setState(
          patch({
            loading: false,
          })
        );

        ctx.dispatch(new LoadFailed());

        throw new Error(e.message || e);
      })
    );
  }

  private _findSupplierTemplates(
    ctx: StateContext<EmailTemplatesStateModel>,
    supplierUuid: string
  ): Observable<EmailTemplates> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.emailService
      .findSupplierTemplates(webshopUuid, supplierUuid)
      .pipe(
        catchError(e => {
          ctx.setState(
            patch({
              loading: false,
            })
          );

          ctx.dispatch(new LoadFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _saveWebshopTemplate(
    ctx: StateContext<EmailTemplatesStateModel>
  ): Observable<any> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const templateProperties = this._buildTemplateProperties(
      ctx.getState().emailTemplateModel
    );

    return this.emailService
      .replaceWebshopTemplate(webshopUuid, templateProperties)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _saveAccountTemplate(
    ctx: StateContext<EmailTemplatesStateModel>
  ): Observable<any> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const templateProperties = this._buildTemplateProperties(
      ctx.getState().emailTemplateModel
    );

    return this.emailService
      .createOrReplaceAccountTemplate(webshopUuid, templateProperties)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _saveSupplierTemplate(
    ctx: StateContext<EmailTemplatesStateModel>,
    supplierUuid: string
  ): Observable<any> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const templateProperties = this._buildTemplateProperties(
      ctx.getState().emailTemplateModel,
      true
    );

    return this.emailService
      .createOrReplaceSupplierTemplate(
        webshopUuid,
        supplierUuid,
        templateProperties
      )
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }

  private _buildTemplateProperties(
    template: EmailTemplateModel,
    typeless: boolean = false
  ): UpsertTemplatePayload {
    if (typeless) {
      return {
        template: {
          language: template.language,
          subject: template.subject,
          body: template.body,
        },
      };
    }

    return {
      template: {
        language: template.language,
        subject: template.subject,
        body: template.body,
        type: template.type,
      },
    };
  }
}
